import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { createCustomTheme } from "./theme";
import useSettings from "./hooks/useSettings";
import { ThemeProvider, CssBaseline, Dialog } from "@mui/material";
import ErrorBoundary from "./errorBoundary/errorBoundary";
import { requestNotificationPermission, onMessageListener } from './firebase';
import { useDispatch } from 'react-redux';
import paymentActions from './redux/actions/PaymentsActions'
import currenciesActions from './redux/actions/CurrenciesActions'
import entitiesActions from './redux/actions/EntityActions';

/* LAYOUTS */
import DefaultLayout from "./layouts/defaultLayout/DefaultLayout";

/* COMPONENTS */
import Loading from "./components/loaders/Loading";
import { Box } from '@mui/material'
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

//UTILS
import { getFieldData } from "./utils/utils";
import rtlCache from './utils/rltCache';


// ACTIONS
import Actions from "./redux/actions/GlobalActions";
import TranslationActions from "./redux/actions/TranslationsActions";
import RestaurantActions from "./redux/actions/RestActions";
import branchActions from "./redux/actions/BranchActions";
import modulesActions from "./redux/actions/ModulesActions";
import authActions from "./redux/actions/AuthActions";
import notificationsActions from "./redux/actions/NotificationsActions"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { toast } from "react-toastify";
import messagesActions from './redux/actions/MessagesActions'
import OfflinePage from './pages/error/OfflinePage'

//STYLES
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

import { useSelector } from "react-redux";
import { setSupportedLanguages } from "./utils/languageService";
import moment from "moment";
import NavigationHandler from "./utils/NavigateTo";


DatePicker.defaultProps = {
  inputFormat: 'DD/MM/YYYY',
};

/* PAGES */
const ErrorPage = lazy(() => import("./pages/error/ErrorPage"));
const ForbiddenPage = lazy(() => import("./pages/error/Forbidden"))

// Login and Signup
const LogIn = lazy(() => import("./pages/auth/Login"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

const reformat = (values) => {
  let final = []
  values?.map((page) => {
    final.push({ name: page.name, route: page.route, isActive: page.isActive })
    if (page.subPage?.length > 0)
      final.push(...reformat(page.subPage))
  })
  return final
}

// rtl-ltr
const ltrCache = createCache({ key: 'mui-ltr' });

const getDirection = (supportedLanguages, language) => {
    const index = supportedLanguages.find(lang => lang.name.toLowerCase() === language.toLowerCase());
    return index ? index.direction : "ltr"; // Default ltr if not found
};

export const App = ({
  getGlobalConfig,
  getTranslations,
  accessToken,
  getRest,
  getBranches,
  getEntities,
  selectedRestaurant,
  selectedBranch,
  branches,
  setSelectedBranch,
  getModules,
  clearAllBranches,
  getMe,
  permissions,
  language,
  loading,
  fcmToken,
  setFcmToken,
  registerDevice,
  getNotifications,
  getChatMessages,
  clearLoading,
  setSelectedRest,
  chats,
  getUserGlobalChats,
  setSelectedRestData,
  restaurants,
  addDefaultPayment,
  generateCurrencies,
  getSupportedLanguages
}) => {
  const supportedLanguages = useSelector((state) => state.translation.supportedLanguages);

  const [direction, setDirection] = useState("ltr");
  const location = useLocation(); 

  useEffect(() => {
    let dir= getDirection(supportedLanguages, language) || 'ltr';
    setDirection(dir);
    
    if (dir === "rtl") {
      
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [language, supportedLanguages]);

  useEffect(() => {
    if (supportedLanguages) {
      setSupportedLanguages(supportedLanguages);
    }
  }, [supportedLanguages]);
  const [blockGetBranches, setBlockGetBranches] = useState(true)

  useEffect(() => {
    if (accessToken) {
      getMe()
      getRest(selectedRestaurant, getBranches, setBlockGetBranches);
      getEntities();
    }
  }, [accessToken]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = async () => {
    const onlineStatus = await checkInternetConnection();
    setIsOnline(onlineStatus);
  };

  const checkInternetConnection = async () => {
    let navOn = navigator.onLine
    let googleOn

    try {
      const response = await fetch('https://www.google.com', {
        method: 'HEAD',
        mode: 'no-cors'
      });
      if (response.ok)
        googleOn = true
    } catch (error) {
      googleOn = false;
    }
    return googleOn || navOn
  };

  useEffect(() => {
    updateOnlineStatus();

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    const intervalId = setInterval(() => {
      updateOnlineStatus();
    }, 300000); // 300000 ms = 5 minutes

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let data = restaurants.find((rest) => rest.uuid === selectedRestaurant)
    if (data)
      setSelectedRestData(data)
  }, [selectedRestaurant])

  // useEffect(() => {
  //   if (selectedRestaurant !== '' && accessToken) {
  //     addDefaultPayment(selectedRestaurant)
  //     generateCurrencies(selectedRestaurant)
  //   }
  // }, [accessToken, selectedRestaurant])

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     clearLoading()
  //   };

  //   // Add the event listener
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const history = useHistory()
  const isFirstRender = useRef(true)
  const allPages = reformat(permissions).filter((data) => data.route && data.isActive)
  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: "LIGHT", //settings.theme,
  });

  useEffect(() => {
    if (accessToken)
      requestNotificationPermission()
        .then((token) => setFcmToken(token))
        .catch((error) => console.error("Error: ", error))
  }, [accessToken])

  useEffect(() => {
    if (accessToken && fcmToken) { registerDevice(fcmToken) }
  }, [fcmToken, accessToken])

  const [notification, setNotification] = useState()

  onMessageListener()
    .then((payload) => {
      getNotifications()
      setNotification(payload)
      let tmp = window.location.pathname.split('/')
      let tmp2 = payload.data.clickAction.split('/')

      // let ch = chats.map((chat) => chat.chat._id)
      if (tmp[1] === 'messaging' && tmp2[3] === 'messaging') {
        if (tmp[2] === tmp2[4] && tmp[4] !== tmp2[6]) {
          getUserGlobalChats(true)
        }
        if (tmp[2] === tmp2[4] && tmp[3] === tmp2[5] && tmp[4] === tmp2[6])
          getChatMessages(tmp[2], tmp[3], tmp[4], 10, 1, true)
      }
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (notification) {
      let url = '/'
      notification.data.clickAction.split('/').map((str, i) => {
        if (i !== 0 && i !== 1 && i !== 2)
          url += str + "/"
      })
      toast(
        <Box onClick={() => {
          setSelectedRest(notification.data.clickAction.split('/')[4])
          history.push(url)
        }}>
          {notification.notification.title}
        </Box>, {
        hideProgressBar: true
      })
    }
  }, [notification])

  const dispatch = useDispatch();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.type === 'NOTIFICATION_CLICKED') {
            dispatch(setSelectedRest(event.data.restaurantId));
          }
        });
      });
    }
  }, [dispatch]);


  useEffect(() => {
    getGlobalConfig();
    getTranslations();
    getModules();
    getSupportedLanguages();
  }, []);





  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (accessToken && selectedRestaurant) {
      // clearAllBranches();
      getBranches(selectedRestaurant);
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (
      selectedRestaurant &&
      branches?.length > 0 &&
      (!selectedBranch ||
        !branches?.find((branch) => {
          return branch?.uuid === selectedBranch;
        }))
    ) {
      setSelectedBranch(branches[0]?.uuid);
    }
  }, [branches, selectedRestaurant]);

  useEffect(() => {
    // document.title = "Resto Admin";
    let selectedPage = allPages.find((page) => page.route === location.pathname)
    document.title = selectedPage ? getFieldData(selectedPage, 'name', language) : 'Resto Admin'
  }, [location.pathname, allPages]);

  // useEffect(() => {
  //   async function setLocaleAndFormatDates() {
  //     if (language) {
  //       const langCode = language.slice(0, 2); // Extract first two characters of the language string
  //       try {
  //         await import(`moment/locale/${langCode}`); // Dynamically import the locale
  //         moment.locale(langCode); // Set the locale
  //       } catch (error) {
  //         console.error(`Locale "${langCode}" not found. Falling back to default.`);
  //         moment.locale("en"); // Fallback to English if locale is not found
  //       }
  //     }
  //   }
  //   setLocaleAndFormatDates();
  // }, [language]);

  if (loading) {
    return <Loading />
  }
  //else
  return (
    <CacheProvider value={direction === 'rtl' ? rtlCache : ltrCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
        <NavigationHandler />
          <Dialog open={!isOnline} fullScreen>
            <OfflinePage />
          </Dialog>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/not-found" component={ErrorPage} />
              <Route path="/forbidden" component={ForbiddenPage} />
              {/*ErrorPage*/}
              {/* <Route exact path="/error">
                  <ErrorPage />
                </Route> */}

              {/* Login and Signup*/}
              <Route exact path="/login" component={LogIn} />
              {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}

              {/* Main Content*/}
              {accessToken ? (
                <Route
                  path="/"
                  render={(props) => <DefaultLayout {...props} />}
                />
              ) : (
                <Redirect to="/login" />
              )}
              <Redirect from="*" to="/not-found" />
              {/* <Route>
                  <Redirect to="/error" />
                </Route> */}
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
  </CacheProvider>
  );
};

// Redux
const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
  selectedRestaurant: state.rest.selectedRest,
  selectedBranch: state.branch.selectedBranch,
  branches: state.branch.branches,
  permissions: state.auth.permissions,
  language: state.translation.language,
  loading: state.translation.loading || state.rest.loading || state.branch.loading || state.translation.supportedLanguagesLoading,
  fcmToken: state.notifications.token,
  chats: state.messages.chats,
  restaurants: state.rest.restaurants
});

const mapDispatchToProps = (dispatch) => ({
  getRest: (ruuid, getBranches, setBlockGetBranches) => dispatch(RestaurantActions.getRest(ruuid, getBranches, setBlockGetBranches)),
  setSelectedRestData: (data) => dispatch(RestaurantActions.setSelectedRestData(data)),
  getEntities: () => dispatch(entitiesActions.getEntities()),
  getGlobalConfig: () => dispatch(Actions.getGlobalConfig()),
  getTranslations: () => dispatch(TranslationActions.getTranslation()),
  getSupportedLanguages: () => dispatch(TranslationActions.getSupportedLanguages()),
  getBranches: (selectedRestaurant) =>
    dispatch(branchActions.getBranches(selectedRestaurant)),
  setSelectedBranch: (data) => dispatch(branchActions.setSelectedBranch(data)),
  clearAllBranches: () => dispatch(branchActions.clearAllBranches()),
  getModules: () => dispatch(modulesActions.getModules()),
  getMe: () => dispatch(authActions.getMe()),
  setFcmToken: (token) => dispatch(notificationsActions.setFcmToken(token)),
  registerDevice: (token) => dispatch(notificationsActions.registerDevice(token)),
  getNotifications: () => dispatch(notificationsActions.getNotifications()),
  getChatMessages: (ruuid, bruuid, chatId, pageLimit, page, disableLoading) => dispatch(messagesActions.getChatMessages(ruuid, bruuid, chatId, pageLimit, page, disableLoading)),
  clearLoading: () => dispatch(Actions.clearLoading()),
  setSelectedRest: (data) => dispatch(RestaurantActions.setSelectedRest(data)),
  getUserGlobalChats: (isSilent) => dispatch(messagesActions.getUserGlobalChats(isSilent)),
  addDefaultPayment: (ruuid) => dispatch(paymentActions.addDefaultPayment(ruuid)),
  generateCurrencies: (ruuid) => dispatch(currenciesActions.generateCurrency(ruuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
