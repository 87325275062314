import { getTranslations, editTranslations, getMissingTranslations, getTranslationPages, editSupportedLanguagesSettings, getSupportedLanguagesSettings, getSupportedLanguages } from '../../config/config'

import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST'
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS'
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR'

export const GET_TRANSLATION_PAGES_REQUEST = 'GET_TRANSLATION_PAGES_REQUEST'
export const GET_TRANSLATION_PAGES_SUCCESS = 'GET_TRANSLATION_PAGES_SUCCESS'
export const GET_TRANSLATION_PAGES_ERROR = 'GET_TRANSLATION_PAGES_ERROR'

export const GET_MISSING_TRANSLATIONS_REQUEST = 'GET_MISSING_TRANSLATIONS_REQUEST'
export const GET_MISSING_TRANSLATIONS_SUCCESS = 'GET_MISSING_TRANSLATIONS_SUCCESS'
export const GET_MISSING_TRANSLATIONS_ERROR = 'GET_MISSING_TRANSLATIONS_ERROR'

export const GET_SUPPORTED_LANGUAGES_SETTINGS_REQUEST = 'GET_SUPPORTED_LANGUAGES_SETTINGS_REQUEST'
export const GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS = 'GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS'
export const GET_SUPPORTED_LANGUAGES_SETTINGS_ERROR = 'GET_SUPPORTED_LANGUAGES_SETTINGS_ERROR'

export const GET_SUPPORTED_LANGUAGES_REQUEST = 'GET_SUPPORTED_LANGUAGES_REQUEST'
export const GET_SUPPORTED_LANGUAGES_SUCCESS = 'GET_SUPPORTED_LANGUAGES_SUCCESS'
export const GET_SUPPORTED_LANGUAGES_ERROR = 'GET_SUPPORTED_LANGUAGES_ERROR'

export const EDIT_SUPPORTED_LANGUAGES_SETTINGS_START = 'EDIT_SUPPORTED_LANGUAGES_SETTINGS_START'
export const EDIT_SUPPORTED_LANGUAGES_SETTINGS_END = 'EDIT_SUPPORTED_LANGUAGES_SETTINGS_END'

export const EDIT_TRANSLATIONS_START = 'EDIT_TRANSLATIONS_START'
export const EDIT_TRANSLATIONS_END = 'EDIT_TRANSLATIONS_END'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const CLEAR_ALL_TRANSLATIONS = 'CLEAR_ALL_TRANSLATIONS'

export const CLEAR_TRANSLATIONS_LOADING = 'CLEAR_TRANSLATIONS_LOADING'

const actions = {
  getSupportedLanguagesSettings: () => async (dispatch) => {
    dispatch({
      type: GET_SUPPORTED_LANGUAGES_SETTINGS_REQUEST
    })
    await getSupportedLanguagesSettings().then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS,
          payload: response.data.languages
        })
      else handleError(dispatch)(response, GET_SUPPORTED_LANGUAGES_SETTINGS_ERROR, 'GET SUPPORTED LANGUAGES SETTINGS ERROR')
    })
  },
  editSupportedLanguagesSettings: (data) => async (dispatch) => {
    dispatch({
      type: EDIT_SUPPORTED_LANGUAGES_SETTINGS_START
    })
    await editSupportedLanguagesSettings(data).then(async (response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'EDIT SUPPORTED LANGUAGES SETTINGS SUCCESS')
        dispatch({
          type: GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS,
          payload: response.data.languages
        })
        await getSupportedLanguages().then((response) => {
          if (response.status === 200)
            dispatch({
              type: GET_SUPPORTED_LANGUAGES_SUCCESS,
              payload: response.data.languages
            })
          else handleError(dispatch)(response, GET_SUPPORTED_LANGUAGES_ERROR, 'GET SUPPORTED LANGUAGES ERROR')
        })
      } else handleError(dispatch)(response, null, 'EDIT SUPPORTED LANGUAGES SETTINGS ERROR')
    })
    dispatch({
      type: EDIT_SUPPORTED_LANGUAGES_SETTINGS_END
    })
  },
  getTranslationPages: () => async (dispatch) => {
    dispatch({
      type: GET_TRANSLATION_PAGES_REQUEST
    })
    await getTranslationPages().then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_TRANSLATION_PAGES_SUCCESS,
          payload: response.data.pages
        })
      else handleError(dispatch)(response, GET_TRANSLATION_PAGES_ERROR, 'GET TRANSLATION PAGES ERROR')
    })
  },
  getSupportedLanguages: () => async (dispatch) => {
    dispatch({
      type: GET_SUPPORTED_LANGUAGES_REQUEST
    })
    await getSupportedLanguages().then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_SUPPORTED_LANGUAGES_SUCCESS,
          payload: response.data.languages
        })
      else handleError(dispatch)(response, GET_SUPPORTED_LANGUAGES_ERROR, 'GET SUPPORTED LANGUAGES ERROR')
    })
  },
  // Get All translations with the selected one
  getTranslation: () => async (dispatch, getState) => {
    dispatch({
      type: GET_TRANSLATION_REQUEST,
    })
    await getTranslations().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: res?.data,
        })
        const defaultLanguage = res?.data.supportedLanguages.find((lang) => lang.isDefault)
        if (defaultLanguage && !Boolean(getState().translation.language))
          dispatch({
            type: SET_LANGUAGE,
            payload: defaultLanguage.name
          })
      } else {
        handleError(dispatch)(res, GET_TRANSLATION_ERROR, 'GET TRANSLATION ERROR')
        // toast.error(res?.data?.message || 'Get All Translation error')
        // dispatch({
        //   type: GET_TRANSLATION_ERROR
        // })
        // if (res.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   })
      }
    })
  },
  setLanguage: (data) => (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: data
    })
  },
  clearAllTranslations: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_TRANSLATIONS
    })
  },
  getMissingTranslations: (pageName, ruuid, limit, page) => async (dispatch) => {
    dispatch({
      type: GET_MISSING_TRANSLATIONS_REQUEST
    })
    await getMissingTranslations(pageName, ruuid, limit, page).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_MISSING_TRANSLATIONS_SUCCESS,
          payload: {
            missingTranslations: response.data.missingTranslations,
            totalRecords: response.data.totalRecords,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages
          }
        })
      } else handleError(dispatch)(response, GET_MISSING_TRANSLATIONS_ERROR, 'GET MISSING TRANSLATIONS ERROR')
    })
  },
  editTranslations: (data, pageName, ruuid, limit, page) => async (dispatch) => {
    dispatch({
      type: EDIT_TRANSLATIONS_START
    })
    await editTranslations(data).then(async (response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'EDIT TRANSLATIONS SUCCESS')
        await getMissingTranslations(pageName, ruuid, limit, page).then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_MISSING_TRANSLATIONS_SUCCESS,
              payload: {
                missingTranslations: response.data.missingTranslations,
                totalRecords: response.data.totalRecords,
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages
              }
            })
          } else handleError(dispatch)(response, GET_MISSING_TRANSLATIONS_ERROR, 'GET MISSING TRANSLATIONS ERROR')
        })
      } else handleError(dispatch)(response, null, 'EDIT TRANSLATIONS ERROR')
    })
    dispatch({
      type: EDIT_TRANSLATIONS_END
    })
  }
}
export default actions
