import { getMenu, addMenu, editMenu, getGroups, updateMenuSorting ,getModifiersGroupsWithModifiers, getIngredients, getTypesAndAllergies, getProducts, getMenus, deleteMenu, editMenuName } from '../../config/config'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { GET_GROUP_ERROR } from './GroupActions'
import { GET_MODIFIERGROUP_DETAILS_ERROR } from './ModifiersGroupsActions'
import { GET_INGREDIENT_ERROR } from './IngredientActions'
import { GET_TYPES_AND_ALLERGIES_ERROR } from './TypesAndAllergiesActions'
import { GET_PRODUCT_ERROR } from './ProductActions'
import { handleError } from '../../utils/utils'

export const GET_MENU_REQUEST = 'GET_MENU_REQUEST'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_ERROR = 'GET_MENU_ERROR'

export const GET_MENUS_REQUEST = 'GET_MENUS_REQUEST'
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS'
export const GET_MENUS_ERROR = 'GET_MENUS_ERROR'

export const ADD_MENU_REQUEST = 'ADD_MENU_REQUEST'
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS'
export const ADD_MENU_ERROR = 'ADD_MENU_ERROR'

export const EDIT_MENU_REQUEST = 'EDIT_MENU_REQUEST'
export const EDIT_MENU_SUCCESS = 'EDIT_MENU_SUCCESS'
export const EDIT_MENU_ERROR = 'EDIT_MENU_ERROR'

export const DELETE_MENU_STARTED = 'DELETE_MENU_STARTED'
export const DELETE_MENU_ENDED = 'DELETE_MENU_ENDED'

export const GET_MENU_DISPLAY_DATA_REQUEST = 'GET_MENU_DISPLAY_DATA_REQUEST'
export const GET_MENU_DISPLAY_DATA_SUCCESS = 'GET_MENU_DISPLAY_DATA_SUCCESS'
export const GET_MENU_DISPLAY_DATA_ERROR = 'GET_MENU_DISPLAY_DATA_ERROR'

export const GET_MULTIMENU_DATA_REQUEST = 'GET_MULTIMENU_DATA_REQUEST'
export const GET_MULTIMENU_DATA_SUCCESS = 'GET_MULTIMENU_DATA_SUCCESS'
export const GET_MULTIMENU_DATA_ERROR = 'GET_MULTIMENU_DATA_ERROR'

export const GET_MENU_DISPLAY_PRODUCTS_REQUEST = 'GET_MENU_DISPLAY_PRODUCTS_REQUEST'
export const GET_MENU_DISPLAY_PRODUCTS_SUCCESS = 'GET_MENU_DISPLAY_PRODUCTS_SUCCESS'
export const GET_MENU_DISPLAY_PRODUCTS_ERROR = 'GET_MENU_DISPLAY_PRODUCTS_ERROR'

export const EDIT_MENU_NAME_STARTED = 'EDIT_MENU_NAME_STARTED'
export const EDIT_MENU_NAME_ENDED = 'EDIT_MENU_NAME_ENDED'

export const EDIT_SORT_ORDER_STARTED = 'EDIT_SORT_ORDER_STARTED'
export const EDIT_SORT_ORDER_ENDED = 'EDIT_SORT_ORDER_ENDED'

export const GET_MENUS_WITH_DATA_STARTED = 'GET_MENUS_WITH_DATA_STARTED'
export const GET_MENUS_WITH_DATA_ENDED = 'GET_MENUS_WITH_DATA_ENDED'

export const GET_MULTIMENUS_REQUEST = 'GET_MULTIMENUS_REQUEST'

export const SET_SELECTED_MENU = 'SET_SELECTED_MENU'

export const CLEAR_MENU = 'CLEAR_MENU'

export const CLEAR_MENU_LOADING = 'CLEAR_MENU_LOADING'

const actions = {
  getMenu: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: GET_MENU_REQUEST,
    })
    await getMenu(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MENU_SUCCESS,
          payload: {
            menu: res?.data?.menu,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MENU_ERROR, 'GET MENU ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get Menu error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MENU_ERROR,
        // })
      }
    })
  },

  addMenu: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: ADD_MENU_REQUEST,
    })

    await addMenu(ruuid, data).then(async (res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: ADD_MENU_SUCCESS,
          payload: {
            menu: res?.data?.menu,
          },
        })
        await getMenus(ruuid).then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_MENUS_SUCCESS,
              payload: response?.data?.menus
            })
            dispatch({
              type: SET_SELECTED_MENU,
              payload: res?.data?.menu.uuid
            })
          } else {
            handleError(dispatch)(response, GET_MENUS_ERROR, 'GET MENUS ERROR')
            // dispatch({
            //   type: GET_MENUS_ERROR
            // })
            // if (response.status === 401)
            //   dispatch({
            //     type: LOGOUT_USER
            //   })
            // if (response.status === 403)
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: response?.data?.permissions
            //   })
          }
        })
      } else {
        handleError(dispatch)(res, ADD_MENU_ERROR, 'ADD MENU ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Menu error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: ADD_MENU_ERROR,
        // })
      }
    })
  },

  editMenu: (ruuid, uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_MENU_REQUEST,
    })

    await editMenu(ruuid, uuid, data).then(async (res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        try {
          const [menu, groups, products] = await Promise.all([
            getMenu(ruuid, uuid),
            getGroups(ruuid),
            // getModifiersGroupsWithModifiers(ruuid),
            //getIngredients(),
            // getTypesAndAllergies(ruuid),
            getProducts(ruuid)
          ])

          const data = {
            products: products.data.products,
            menu: menu.data.menu,
            //typesAndAllergies: typesAndAllergies.data.foodtypesAndAlergies,
            //ingredients: ingredients.data.ingredients,
            // modifierGroups: modifierGroups.data.modifierGroups,
            groups: groups.data.groups
          }

          if (menu.status !== 200 || groups.status !== 200 || products.status !== 200) {
            throw new Error()
          }
          dispatch({
            type: EDIT_MENU_SUCCESS,
            payload: data
          })
        } catch (err) {
          if (res.status !== 401 && res.status !== 403)
            toast.error('Something went wrong')
          dispatch({
            type: EDIT_MENU_ERROR,
          })
        }
      } else {
        handleError(dispatch)(res, EDIT_MENU_ERROR, 'EDIT MENU ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Menu error')
        // dispatch({
        //   type: EDIT_MENU_ERROR,
        // })
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
  },
  updateMenuSorting: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_SORT_ORDER_STARTED,
    })
    await updateMenuSorting(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'SORT ORDER SUCCESS')
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: response?.data?.menus
        })
      } else {
        handleError(dispatch)(response, null, 'SORT ORDER ERROR')
      }
    })
    dispatch({
      type: EDIT_SORT_ORDER_ENDED
    })
  },
  getMenus: (ruuid, setSelectedMenu, selectedMenu, setBlockGet, isMultiMenu) => async (dispatch) => {
    if (setBlockGet)
      setBlockGet(true)
    dispatch({
      type: !isMultiMenu ? GET_MENUS_REQUEST : GET_MULTIMENUS_REQUEST
    })
    await getMenus(ruuid).then((response) => {
      if (response.status === 200) {
        if (!response?.data?.menus.some((menu) => menu.uuid === selectedMenu)) {
          if (setSelectedMenu) {
            setSelectedMenu(response?.data.menus[0].uuid)
          }
        }
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: response?.data?.menus
        })
      } else {
        handleError(dispatch)(response, GET_MENUS_ERROR, 'GET MENUS ERROR')
        // dispatch({
        //   type: GET_MENUS_ERROR
        // })
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    if (setBlockGet)
      setBlockGet(false)
  },
  getMenusWithData: (ruuid, setSelectedMenu, selectedMenu) => async (dispatch, getState) => {
    if (selectedMenu !== getState().menu.menu.menuCache)
      dispatch({
        type: GET_MENUS_WITH_DATA_STARTED
      })
    let selectedMenuLocal = selectedMenu
    await getMenus(ruuid).then(async (response) => {
      if (response.status === 200) {
        if (!response?.data?.menus.some((menu) => menu.uuid === selectedMenu)) {
          if (setSelectedMenu) {
            if (response?.data?.menus.length > 1) {
              setSelectedMenu(response?.data.menus[1].uuid)
              selectedMenuLocal = response?.data.menus[1].uuid
            }
            else {
              setSelectedMenu(response?.data.menus[0]?.uuid)
              selectedMenuLocal = response?.data.menus[0]?.uuid
            }
          }
        }
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: response?.data?.menus
        })
        try {
          const [menu, groups, products] = await Promise.all([
            getMenu(ruuid, selectedMenuLocal),
            getGroups(ruuid),
            //getModifiersGroupsWithModifiers(ruuid),
            //getIngredients(),
            //getTypesAndAllergies(ruuid),
            getProducts(ruuid)
          ])

          const data = {
            products: products.data.products,
            menu: menu.data.menu,
            // typesAndAllergies: typesAndAllergies.data.foodtypesAndAlergies,
            // ingredients: ingredients.data.ingredients,
            // modifierGroups: modifierGroups.data.modifierGroups,
            groups: groups.data.groups,
            menuCache: selectedMenuLocal
          }

          if (menu.status !== 200 || groups.status !== 200 || products.status !== 200) {
            throw new Error()
          }

          dispatch({
            type: GET_MENU_DISPLAY_DATA_SUCCESS,
            payload: data
          })
        } catch (err) {
          toast.error('Something went wrong')
          dispatch({
            type: GET_MENU_DISPLAY_DATA_ERROR
          })
        }
      } else {
        handleError(dispatch)(response, GET_MENUS_ERROR, 'GET MENUS ERROR')
        // dispatch({
        //   type: GET_MENUS_ERROR
        // })
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })

    dispatch({
      type: GET_MENUS_WITH_DATA_ENDED
    })
  },
  deleteMenu: (ruuid, uuid, close) => async (dispatch) => {
    dispatch({
      type: DELETE_MENU_STARTED
    })
    await deleteMenu(ruuid, uuid).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'DELETE MENU SUCCESS')
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: response?.data?.menus
        })
        if (close)
          close()
      } else {
        handleError(dispatch)(response, null, 'DELETE MENU ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'DELETE MENU ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: DELETE_MENU_ENDED
    })
  },

  // ADDED promis.all in order to increase the performance of the function
  // the above function does returns the same result
  getMenuDisplayData: (ruuid, uuid, isMultiMenu) => async (dispatch, getState) => {
    try {
      if (uuid !== getState().menu.menu.menuCache)
        dispatch({ type: !isMultiMenu ? GET_MENU_DISPLAY_DATA_REQUEST : GET_MULTIMENU_DATA_REQUEST })
      const [menu, groups, products] = await Promise.all([
        getMenu(ruuid, uuid),
        getGroups(ruuid),
        //getModifiersGroupsWithModifiers(ruuid),
        // getIngredients(),
        // getTypesAndAllergies(ruuid),
        getProducts(ruuid)
      ])

      const data = {
        products: products.data.products,
        menu: menu.data.menu,
        // typesAndAllergies: typesAndAllergies.data.foodtypesAndAlergies,
        // ingredients: ingredients.data.ingredients,
        // modifierGroups: modifierGroups.data.modifierGroups,
        groups: groups.data.groups
      }

      if (menu.status !== 200 || groups.status !== 200 || products.status !== 200) {
        throw new Error()
      }

      dispatch({
        type: GET_MENU_DISPLAY_DATA_SUCCESS,
        payload: data
      })
    } catch (err) {
      toast.error('Something went wrong front-end')
      dispatch({
        type: GET_MENU_DISPLAY_DATA_ERROR
      })
    }
  },
  setSelectedMenu: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_MENU,
      payload: data
    })
  },
  editMenuName: (ruuid, uuid, data, getMenu) => async (dispatch) => {
    dispatch({
      type: EDIT_MENU_NAME_STARTED
    })
    await editMenuName(ruuid, uuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'EDIT MENU NAME SUCCESS')
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: response?.data?.menus
        })
        getMenu(ruuid, uuid)
        dispatch({
          type: SET_SELECTED_MENU,
          payload: response?.data.menus.find(menu => menu.uuid === uuid)?.uuid
        })
      } else {
        handleError(dispatch)(response, null, 'EDIT MENU NAME ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'EDIT MENU NAME ERROR')
      }
    })
    dispatch({
      type: EDIT_MENU_NAME_ENDED
    })
  },
  clearMenu: () => (dispatch) => {
    dispatch({
      type: CLEAR_MENU
    })
  }
}

export default actions
