import {
  GET_ENTITIES_REQUEST,
  GET_ENTITIES_SUCCESS,
  GET_ENTITIES_ERROR,
  ADD_ENTITY_STARTED,
  ADD_ENTITY_END,
  EDIT_ENTITY_STARTED,
  EDIT_ENTITY_END,
  DELETE_ENTITY_STARTED,
  DELETE_ENTITY_END,
  SET_ENTITY_POPUP,
  SET_SELECTED_ENTITY,
  SET_IS_ENTITY_SELECTION,
  CLEAR_ALL_ENTITIES
} from "../actions/EntityActions";

const initialState = {
  loading: false,
  entities: [],
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  bulkLoading: false,
  entityPopup: false,
  selectedEntity: null,
  isEntitySelection: false
};

function entities(state = initialState, action) {
  switch (action.type) {
    case GET_ENTITIES_REQUEST:
        return Object.assign({}, state, {
          loading: true
        })
    case GET_ENTITIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: action.payload.entities
    })
    case GET_ENTITIES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        entities: []
      })
    case ADD_ENTITY_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      });

    case ADD_ENTITY_END:
      return Object.assign({}, state, {
        addLoading: false,
      });

    case EDIT_ENTITY_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      });

    case EDIT_ENTITY_END:
      return Object.assign({}, state, {
        editLoading: false,
      });

    case DELETE_ENTITY_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      });

    case DELETE_ENTITY_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      });

    case SET_ENTITY_POPUP:
      return Object.assign({}, state, {
        entityPopup: action.payload,
     });

    case SET_SELECTED_ENTITY:
      return Object.assign({}, state, {
        selectedEntity: action.payload,
     });

     case SET_IS_ENTITY_SELECTION:
      return Object.assign({}, state, {
        isEntitySelection: action.payload,
     });

    case CLEAR_ALL_ENTITIES:
      return initialState

    default:
      return state;
  }
}

export default entities;
