import {
  GET_TRANSLATION_REQUEST,
  GET_TRANSLATION_SUCCESS,
  GET_TRANSLATION_ERROR,
  SET_LANGUAGE,
  CLEAR_ALL_TRANSLATIONS,
  CLEAR_TRANSLATIONS_LOADING,

  GET_MISSING_TRANSLATIONS_REQUEST,
  GET_MISSING_TRANSLATIONS_SUCCESS,
  GET_MISSING_TRANSLATIONS_ERROR,

  GET_TRANSLATION_PAGES_REQUEST,
  GET_TRANSLATION_PAGES_SUCCESS,
  GET_TRANSLATION_PAGES_ERROR,

  GET_SUPPORTED_LANGUAGES_REQUEST,
  GET_SUPPORTED_LANGUAGES_SUCCESS,
  GET_SUPPORTED_LANGUAGES_ERROR,

  EDIT_TRANSLATIONS_START,
  EDIT_TRANSLATIONS_END,

  GET_SUPPORTED_LANGUAGES_SETTINGS_REQUEST,
  GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS,
  GET_SUPPORTED_LANGUAGES_SETTINGS_ERROR,

  EDIT_SUPPORTED_LANGUAGES_SETTINGS_START,
  EDIT_SUPPORTED_LANGUAGES_SETTINGS_END
} from '../actions/TranslationsActions'

const initialState = {
  language: '',
  loading: false,
  supportedLanguagesLoading: false,
  translation: [],
  supportedLanguages: [],
  translationsLoading: false,
  missingTranslations: [],
  editLoading: false,
  totalRecords: 0,
  currentPage: 1,
  totalPages: 0,
  pagesLoading: false,
  pages: [],
  languagesSettings: [],
  settingsLoading: false,
  editSettingsLoading: false
}

function translation(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORTED_LANGUAGES_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        settingsLoading: true
      })
    case GET_SUPPORTED_LANGUAGES_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        settingsLoading: false,
        languagesSettings: action.payload
      })
    case GET_SUPPORTED_LANGUAGES_SETTINGS_ERROR:
      return Object.assign({}, state, {
        settingsLoading: false,
        languagesSettings: []
      })
    case EDIT_SUPPORTED_LANGUAGES_SETTINGS_START:
      return Object.assign({}, state, {
        editSettingsLoading: true
      })
    case EDIT_SUPPORTED_LANGUAGES_SETTINGS_END:
      return Object.assign({}, state, {
        editSettingsLoading: false
      })
    case GET_TRANSLATION_PAGES_ERROR:
      return Object.assign({}, state, {
        pagesLoading: false,
        pages: []
      })
    case GET_TRANSLATION_PAGES_SUCCESS:
      return Object.assign({}, state, {
        pagesLoading: false,
        pages: action.payload
      })
    case GET_TRANSLATION_PAGES_REQUEST:
      return Object.assign({}, state, {
        pagesLoading: true
      })
    case EDIT_TRANSLATIONS_END:
      return Object.assign({}, state, {
        editLoading: false
      })
    case EDIT_TRANSLATIONS_START:
      return Object.assign({}, state, {
        editLoading: true
      })
    case GET_MISSING_TRANSLATIONS_ERROR:
      return Object.assign({}, state, {
        translationsLoading: false,
        missingTranslations: []
      })
    case GET_MISSING_TRANSLATIONS_SUCCESS:
      return Object.assign({}, state, {
        translationsLoading: false,
        missingTranslations: action.payload.missingTranslations,
        totalRecords: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages
      })
    case GET_MISSING_TRANSLATIONS_REQUEST:
      return Object.assign({}, state, {
        translationsLoading: true
      })
    case CLEAR_TRANSLATIONS_LOADING:
      return Object.assign({}, state, {
        loading: false
      })
    case GET_TRANSLATION_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_TRANSLATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        translation: action.payload.translations,
        // supportedLanguages: action.payload.supportedLanguages,
      })
    case GET_TRANSLATION_ERROR:
      return Object.assign({}, state, {
        loading: false,
      })
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        language: action.payload
      })
    case CLEAR_ALL_TRANSLATIONS:
      return initialState
    case GET_SUPPORTED_LANGUAGES_REQUEST:
      return Object.assign({}, state, {
        supportedLanguagesLoading: true
      })
    case GET_SUPPORTED_LANGUAGES_SUCCESS:
      return Object.assign({}, state, {
        supportedLanguagesLoading: false,
        supportedLanguages: action.payload
      })
    case GET_SUPPORTED_LANGUAGES_ERROR:
      return Object.assign({}, state, {
        supportedLanguagesLoading: false,
        supportedLanguages: []
      })
    default:
      return state
  }
}

export default translation
