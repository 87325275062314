import axios from 'axios';
import { CLOUD9_API, MESSAGING, CLOUD9_API_DYNAMIC } from '../constants';


// List of subdomains to rotate through
const subdomains = Array.from({ length: 1000 }, (_, i) => `${i + 1}`);

let currentSubdomainIndex = 0;

// Queue to hold requests
const requestQueue = [];
const BATCH_SIZE = 6;

// Function to get the next subdomain
function getNextSubdomain() {
  const subdomain = subdomains[currentSubdomainIndex];
  currentSubdomainIndex = (currentSubdomainIndex + 1) % subdomains.length;
  return subdomain;
}

// Function to process the queue
async function processQueue() {
  while (requestQueue.length > 0) {
    const batch = requestQueue.splice(0, BATCH_SIZE);
    // const subdomain = getNextSubdomain();
    // const baseURL = `https://${subdomain}.${CLOUD9_API_DYNAMIC}`;
    const baseURL = `${CLOUD9_API}`;

    // Create a new axios instance for this batch with the correct baseURL
    const axiosInstance = axios.create({ baseURL });

    // Apply interceptors and headers to the axios instance
    axiosInstance.interceptors.request.use(
      (req) => {
        const token = localStorage.getItem('access_token');
        req.headers['incoming-origin'] = incomingOrigin;
        req.headers.Authorization = `Bearer ${token}`;
        return req;
      },
      (err) => {
        throw err;
      }
    );

    // // Process each request in the batch
    // const requests = batch.map((request) => {
    //   return axiosInstance({
    //     method: request.config.method,
    //     url: request.config.url,
    //     data: request.config.data,
    //     headers: request.config.headers,
    //   })
    //     .then((response) => ({ success: true, response }))
    //     .catch((error) => ({ success: false, response: error }));
    // });

    // const results = await Promise.all(requests);
    // currentSubdomainIndex = 0
    // // Resolve or reject the promises for each request in the batch
    // batch.forEach((request, index) => {
    //   if (results[index].success) {
    //     request.resolve(results[index].response);
    //   } else {
    //     request.reject(results[index].response);
    //   }
    // });

    batch.forEach((request) => {
      axiosInstance({
        method: request.config.method,
        url: request.config.url,
        data: request.config.data,
        headers: request.config.headers,
      })
        .then((response) => {
          // Resolve the request as soon as it succeeds
          request.resolve(response);
        })
        .catch((error) => {
          // Reject the request as soon as it fails
          request.reject(error);
        });
    });
  }
}

// Function to add a request to the queue
function addRequestToQueue(config) {
  return new Promise((resolve, reject) => {
    // Add the request to the queue with resolve and reject callbacks
    requestQueue.push({ config, resolve, reject });

    // Process the queue immediately if it reaches the batch size
    if (requestQueue.length >= BATCH_SIZE) {
      processQueue();
    } else {
      // Process the queue after a short delay if it doesn't reach the batch size
      setTimeout(() => {
        if (requestQueue.length > 0) {
          processQueue();
        }
      }, 100); // Adjust the delay as needed (e.g., 100ms)
    }
  });
}

// Override Axios methods to add requests to the queue
function createQueuedAxiosInstance(isPrivate = true) {
  return {
    request: (config) => {
      return addRequestToQueue({ ...config, isPrivate });
    },
    get: (url, config) => {
      return addRequestToQueue({ method: 'get', url, ...config, isPrivate });
    },
    post: (url, data, config) => {
      return addRequestToQueue({ method: 'post', url, data, ...config, isPrivate });
    },
    put: (url, data, config) => {
      return addRequestToQueue({ method: 'put', url, data, ...config, isPrivate });
    },
    delete: (url, config) => {
      return addRequestToQueue({ method: 'delete', url, ...config, isPrivate });
    },
  };
}

// Replace existing Axios instances with queued versions
export const PrivateApiCall = createQueuedAxiosInstance(true);
export const PublicApiCall = createQueuedAxiosInstance(false);
export const PrivateApiCallMessaging = axios.create({
  baseURL: MESSAGING
})

PrivateApiCallMessaging.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('access_token')
    req.headers['incoming-origin'] = incomingOrigin
    req.headers.Authorization = `Bearer ${token}`
    return req
  },
  (err) => {
    throw err
  },
)

PrivateApiCallMessaging.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    throw err
  },
)


// Function to get the incoming origin
const getIncomingOrigin = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  if (isProduction) {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    const index = parts.indexOf('admin');

    if (index === 1) {
      return parts[index - 1];
    } else {
      return ''; // No subdomain found
    }
  } else {
    return 'delivery'; // In development, always use 'delivery'
  }
};

export const incomingOrigin = getIncomingOrigin();