import {
    getLoyalty,
    getLoyaltyDescription,
    getLoyaltyUsers,
    addEditLoyalty,
    addEditLoyaltyDescription,
    addLoyaltyConfig,
    getLoyaltyConfig,
    getLoyaltyMenu,
    addEditLoyaltyMenu,
    getCashback,
    getGifts,
    getGift,
    addGift,
    editGift,
    deleteGift,
    addEditCashback,
    getRewardsBasedOnLevel,
    getRewardBasedOnLevel,
    addRewardBasedOnLevel,
    editRewardBasedOnLevel,
    deleteRewardBasedOnLevel,
    editLoyaltyPoints,
    getLoyaltyUserEntries
} from "../../config/config";

import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from "../../utils/utils";

export const GET_LOYALTY_USER_ENTRIES_REQUEST = 'GET_LOYALTY_USER_ENTRIES_REQUEST'
export const GET_LOYALTY_USER_ENTRIES_SUCCESS = 'GET_LOYALTY_USER_ENTRIES_SUCCESS'
export const GET_LOYALTY_USER_ENTRIES_ERROR = 'GET_LOYALTY_USER_ENTRIES_ERROR'

export const GET_LOYALTY_REQUEST = 'GET_LOYALTY_REQUEST'
export const GET_LOYALTY_SUCCESS = 'GET_LOYALTY_SUCCESS'
export const GET_LOYALTY_ERROR = 'GET_LOYALTY_ERROR'

export const GET_LOYALTY_DESCRIPTION_REQUEST = 'GET_LOYALTY_DESCRIPTION_REQUEST'
export const GET_LOYALTY_DESCRIPTION_SUCCESS = 'GET_LOYALTY_DESCRIPTION_SUCCESS'
export const GET_LOYALTY_DESCRIPTION_ERROR = 'GET_LOYALTY_DESCRIPTION_ERROR'

export const GET_LOYALTY_USERS_REQUEST = 'GET_LOYALTY_USERS_REQUEST'
export const GET_LOYALTY_USERS_SUCCESS = 'GET_LOYALTY_USERS_SUCCESS'
export const GET_LOYALTY_USERS_ERROR = 'GET_LOYALTY_USERS_ERROR'

export const GET_LOYALTY_CONFIG_REQUEST = 'GET_LOYALTY_CONFIG_REQUEST'
export const GET_LOYALTY_CONFIG_SUCCESS = 'GET_LOYALTY_CONFIG_SUCCESS'
export const GET_LOYALTY_CONFIG_ERROR = 'GET_LOYALTY_CONFIG_ERROR'

export const GET_LOYALTY_MENU_REQUEST = 'GET_LOYALTY_MENU_REQUEST'
export const GET_LOYALTY_MENU_SUCCESS = 'GET_LOYALTY_MENU_SUCCESS'
export const GET_LOYALTY_MENU_ERROR = 'GET_LOYALTY_MENU_ERROR'

export const GET_LOYALTY_CASHBACK_REQUEST = 'GET_LOYALTY_CASHBACK_REQUEST'
export const GET_LOYALTY_CASHBACK_SUCCESS = 'GET_LOYALTY_CASHBACK_SUCCESS'
export const GET_LOYALTY_CASHBACK_ERROR = 'GET_LOYALTY_CASHBACK_ERROR'

export const GET_LOYALTY_GIFTS_REQUEST = 'GET_LOYALTY_GIFTS_REQUEST'
export const GET_LOYALTY_GIFTS_SUCCESS = 'GET_LOYALTY_GIFTS_SUCCESS'
export const GET_LOYALTY_GIFTS_ERROR = 'GET_LOYALTY_GIFTS_ERROR'

export const GET_LOYALTY_GIFT_REQUEST = 'GET_LOYALTY_GIFT_REQUEST'
export const GET_LOYALTY_GIFT_SUCCESS = 'GET_LOYALTY_GIFT_SUCCESS'
export const GET_LOYALTY_GIFT_ERROR = 'GET_LOYALTY_GIFT_ERROR'

export const GET_REWARDS_BASED_ON_LEVEL_REQUEST = 'GET_REWARDS_BASED_ON_LEVEL_REQUEST'
export const GET_REWARDS_BASED_ON_LEVEL_SUCCESS = 'GET_REWARDS_BASED_ON_LEVEL_SUCCESS'
export const GET_REWARDS_BASED_ON_LEVEL_ERROR = 'GET_REWARDS_BASED_ON_LEVEL_ERROR'

export const GET_REWARD_BASED_ON_LEVEL_REQUEST = 'GET_REWARD_BASED_ON_LEVEL_REQUEST'
export const GET_REWARD_BASED_ON_LEVEL_SUCCESS = 'GET_REWARD_BASED_ON_LEVEL_SUCCESS'
export const GET_REWARD_BASED_ON_LEVEL_ERROR = 'GET_REWARD_BASED_ON_LEVEL_ERROR'

export const ADD_REWARD_BASED_ON_LEVEL_STARTED = 'ADD_REWARD_BASED_ON_LEVEL_STARTED'
export const ADD_REWARD_BASED_ON_LEVEL_ENDED = 'ADD_REWARD_BASED_ON_LEVEL_ENDED'

export const EDIT_REWARD_BASED_ON_LEVEL_STARTED = 'EDIT_REWARD_BASED_ON_LEVEL_STARTED'
export const EDIT_REWARD_BASED_ON_LEVEL_ENDED = 'EDIT_REWARD_BASED_ON_LEVEL_ENDED'

export const DELETE_REWARD_BASED_ON_LEVEL_STARTED = 'DELETE_REWARD_BASED_ON_LEVEL_STARTED'
export const DELETE_REWARD_BASED_ON_LEVEL_ENDED = 'DELETE_REWARD_BASED_ON_LEVEL_ENDED'

export const ADD_EDIT_LOYALTY_MENU_STARTED = 'ADD_EDIT_LOYALTY_MENU_STARTED'
export const ADD_EDIT_LOYALTY_MENU_ENDED = 'ADD_EDIT_LOYALTY_MENU_ENDED'

export const ADD_LOYALTY_GIFT_STARTED = 'ADD_LOYALTY_GIFT_STARTED'
export const ADD_LOYALTY_GIFT_ENDED = 'ADD_LOYALTY_GIFT_ENDED'

export const EDIT_LOYALTY_GIFT_STARTED = 'EDIT_LOYALTY_GIFT_STARTED'
export const EDIT_LOYALTY_GIFT_ENDED = 'EDIT_LOYALTY_GIFT_ENDED'

export const DELETE_LOYALTY_GIFT_STARTED = 'DELETE_LOYALTY_GIFT_STARTED'
export const DELETE_LOYALTY_GIFT_ENDED = 'DELETE_LOYALTY_GIFT_ENDED'

export const ADD_EDIT_LOYALTY_STARTED = 'ADD_EDIT_LOYALTY_STARTED'
export const ADD_EDIT_LOYALTY_ENDED = 'ADD_EDIT_LOYALTY_ENDED'

export const ADD_EDIT_LOYALTY_DESCRIPTION_STARTED = 'ADD_EDIT_LOYALTY_DESCRIPTION_STARTED'
export const ADD_EDIT_LOYALTY_DESCRIPTION_ENDED = 'ADD_EDIT_LOYALTY_DESCRIPTION_ENDED'

export const ADD_LOYALTY_CONFIG_STARTED = 'ADD_LOYALTY_CONFIG_STARTED'
export const ADD_LOYALTY_CONFIG_ENDED = 'ADD_LOYALTY_CONFIG_ENDED'

export const ADD_EDIT_LOYALTY_CASHBACK_STARTED = 'ADD_EDIT_LOYALTY_CASHBACK_STARTED'
export const ADD_EDIT_LOYALTY_CASHBACK_ENDED = 'ADD_EDIT_LOYALTY_CASHBACK_ENDED'

export const SET_LOYALTY_SETTINGS_MODAL_STATE = 'SET_LOYALTY_SETTINGS_MODAL_STATE'

export const SET_EDIT_GIFT = 'SET_EDIT_GIFT'

export const SET_EDIT_REWARD_BASED_ON_LEVEL = 'SET_EDIT_REWARD_BASED_ON_LEVEL'

export const EDIT_LOYALTY_POINTS_STARTED = 'EDIT_LOYALTY_POINTS_STARTED'
export const EDIT_LOYALTY_POINTS_ENDED = 'EDIT_LOYALTY_POINTS_ENDED'

export const SET_LOYALTY_LEVEL_FILTER = 'SET_LOYALTY_LEVEL_FILTER'

export const CLEAR_ALL_LOYALTY = 'CLEAR_ALL_LOYALTY'

export const CLEAR_LOYALTY_LOADING = 'CLEAR_LOYALTY_LOADING'

const actions = {
    getLoyaltyUserEntries: (uuid, ruuid) => async (dispatch) => {
        dispatch({
            type: GET_LOYALTY_USER_ENTRIES_REQUEST
        })
        await getLoyaltyUserEntries(ruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_USER_ENTRIES_SUCCESS,
                    payload: response.data.entries
                })
            else handleError(response, GET_LOYALTY_USER_ENTRIES_ERROR, 'GET LOYALTY USER ENTRIES ERROR')
        })
    },
    setLoyaltyLevelFilter: (data) => (dispatch) => {
        dispatch({
            type: SET_LOYALTY_LEVEL_FILTER,
            payload: data
        })
    },
    setEditGift: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_GIFT,
            payload: data
        })
    },
    getLoyalty: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantLoyalty
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_REQUEST,
                payload: ruuid
            })
        await getLoyalty(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_LOYALTY_SUCCESS,
                    payload: { loyalty: response.data.loyality }
                })
            }
            else {
                handleError(dispatch)(response, GET_LOYALTY_ERROR, 'GET LOYALTY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get loyalty error')
                // dispatch({
                //     type: GET_LOYALTY_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getLoyaltyDescription: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantDescription
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_DESCRIPTION_REQUEST,
                payload: ruuid
            })
        await getLoyaltyDescription(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_LOYALTY_DESCRIPTION_SUCCESS,
                    payload: { description: response.data[0]?.description }
                })
            }
            else {
                handleError(dispatch)(response, GET_LOYALTY_DESCRIPTION_ERROR, 'GET LOYALTY DESCRIPTION ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Get loyalty descrption error')
                // dispatch({
                //     type: GET_LOYALTY_DESCRIPTION_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getLoyaltyUsers: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantLoyaltyUsers
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_USERS_REQUEST,
                payload: ruuid
            })
        await getLoyaltyUsers(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_LOYALTY_USERS_SUCCESS,
                    payload: { users: response.data }
                })
            }
            else {
                handleError(dispatch)(response, GET_LOYALTY_USERS_ERROR, 'GET LOYALTY USERS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Get loyalty error')
                // dispatch({
                //     type: GET_LOYALTY_USERS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addEditLoyalty: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_LOYALTY_STARTED
        })
        await addEditLoyalty(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD EDIT LOYALTY SUCCESS')
                dispatch({
                    type: GET_LOYALTY_SUCCESS,
                    payload: { loyalty: response?.data?.Loyalty }
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD EDIT LOYALTY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD EDIT LOYALTY ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_EDIT_LOYALTY_ENDED
        })
    },
    addEditLoyaltyDescription: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_LOYALTY_DESCRIPTION_STARTED
        })
        await addEditLoyaltyDescription(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD EDIT LOYALTY SUCCESS')
                dispatch({
                    type: GET_LOYALTY_DESCRIPTION_SUCCESS,
                    payload: { description: response?.data?.Loyalty.description }
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD EDIT LOYALTY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD EDIT LOYALTY ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_EDIT_LOYALTY_DESCRIPTION_ENDED
        })
    },
    setSettingsModalState: (data) => (dispatch) => {
        dispatch({
            type: SET_LOYALTY_SETTINGS_MODAL_STATE,
            payload: data
        })
    },
    addLoyaltyConfig: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: ADD_LOYALTY_CONFIG_STARTED
        })
        await addLoyaltyConfig(ruuid, data).then(async (response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD LOYALTY CONFIG SUCCESS')
                await getLoyaltyConfig(ruuid).then((response) => {
                    if (response.status === 200)
                        dispatch({
                            type: GET_LOYALTY_CONFIG_SUCCESS,
                            payload: response.data.generalConfigurations?.find((config) => config.module === 'loyalty')
                        })
                    else {
                        handleError(dispatch)(response, GET_LOYALTY_CONFIG_ERROR, 'GET LOYALTY CONFIG ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response.data?.message || 'GET LOYALTY CONFIG ERROR')
                        // dispatch({
                        //     type: GET_LOYALTY_CONFIG_ERROR
                        // })
                        // if (response.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
                if (onClose)
                    onClose()
            }
            else {
                handleError(dispatch)(response, null, 'ADD LOYALTY CONFIG ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD LOYALTY CONFIG ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_LOYALTY_CONFIG_ENDED
        })
    },
    getLoyaltyConfig: (ruuid) => async (dispatch) => {
        dispatch({
            type: GET_LOYALTY_CONFIG_REQUEST
        })
        await getLoyaltyConfig(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_CONFIG_SUCCESS,
                    payload: response.data.generalConfigurations?.find((config) => config.module === 'loyalty')
                })
            else {
                handleError(dispatch)(response, GET_LOYALTY_CONFIG_ERROR, 'GET LOYALTY CONFIG ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'GET LOYALTY CONFIG ERROR')
                // dispatch({
                //     type: GET_LOYALTY_CONFIG_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getLoyaltyMenu: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantMenu
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_MENU_REQUEST,
                payload: ruuid
            })
        await getLoyaltyMenu(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_MENU_SUCCESS,
                    payload: response.data[0]
                })
            else {
                handleError(dispatch)(response, GET_LOYALTY_MENU_ERROR, 'GET LOYALTY MENU ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET LOYALTY MENU ERROR')
                // dispatch({
                //     type: GET_LOYALTY_MENU_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getCashback: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantCashback
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_CASHBACK_REQUEST,
                payload: ruuid
            })
        await getCashback(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_CASHBACK_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_LOYALTY_CASHBACK_ERROR, 'GET LOYALTY CASHBACK ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET LOYALTY CASHBACK ERROR')
                // dispatch({
                //     type: GET_LOYALTY_CASHBACK_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getGifts: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantGifts
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_GIFTS_REQUEST,
                payload: ruuid
            })
        await getGifts(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_GIFTS_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_LOYALTY_GIFTS_ERROR, 'GET LOYALTY GIFTS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET LOYALTY GIFTS ERROR')
                // dispatch({
                //     type: GET_LOYALTY_GIFTS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getGift: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantGift
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_LOYALTY_GIFT_REQUEST,
                payload: ruuid
            })
        await getGift(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_LOYALTY_GIFT_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_LOYALTY_GIFT_ERROR, 'GET LOYALTY GIFT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET LOYALTY GIFT ERROR')
                // dispatch({
                //     type: GET_LOYALTY_GIFT_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addEditLoyaltyMenu: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_LOYALTY_MENU_STARTED
        });
    
        let message = '';
        let success = false;
    
        try {
            // Create an array of promises
            const promises = data.map(item => addEditLoyaltyMenu(ruuid, item))
    
            // Execute all promises concurrently
            const responses = await Promise.all(promises)
    
            // Check the responses
            success = responses.every(response => response.status === 200)
    
            if (success) {
                message = responses[0]?.data?.message || 'EDIT LOYALTY MENU SUCCESS'
            } else {
                responses.forEach(response => {
                    if (response.status !== 200) {
                        handleError(dispatch)(response, null, 'EDIT BULK ERROR')
                    }
                })
            }
    
            if (success) {
                const response = await getLoyaltyMenu(ruuid)
                if (response.status === 200) {
                    dispatch({
                        type: GET_LOYALTY_MENU_SUCCESS,
                        payload: response.data[0]
                    })
                } else {
                    handleError(dispatch)(response, GET_LOYALTY_MENU_ERROR, 'GET LOYALTY MENU ERROR')
                }
                toast.success(message)
            }
        } catch (err) {
            console.error(err)
            toast.error('An unexpected error occurred during the bulk edit.')
        } finally {
            dispatch({
                type: ADD_EDIT_LOYALTY_MENU_ENDED
            })
        }
    },
    addEditCashback: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_LOYALTY_CASHBACK_STARTED
        })
        await addEditCashback(ruuid, data).then(async (response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD EDIT LOYALTY CASHBACK SUCCESS')
                dispatch({
                    type: GET_LOYALTY_CASHBACK_SUCCESS,
                    payload: { loyaltyCashbacks: response.data.loyaltyCashbacks }
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD EDIT LOYALTY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD EDIT LOYALTY ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_EDIT_LOYALTY_CASHBACK_ENDED
        })
    },
    addGift: (ruuid, data, history) => async (dispatch) => {
        dispatch({
            type: ADD_LOYALTY_GIFT_STARTED
        })
        await addGift(ruuid, data).then((response) => {
            if (response.status === 200) {
                history()
                toast.success(response.data.message || 'ADD LOYALTY GIFT SUCCESS')
                dispatch({
                    type: GET_LOYALTY_GIFTS_SUCCESS,
                    payload: response.data.loyaltyGifts
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD LOYALTY GIFT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD LOYALTY GIFT ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_LOYALTY_GIFT_ENDED
        })
    },
    editGift: (ruuid, uuid, data, setEditGift, history) => async (dispatch) => {
        dispatch({
            type: EDIT_LOYALTY_GIFT_STARTED
        })
        await editGift(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                history()
                setEditGift()
                toast.success(response.data.message || 'EDIT LOYALTY GIFT SUCCESS')
                dispatch({
                    type: GET_LOYALTY_GIFTS_SUCCESS,
                    payload: response.data.loyaltyGifts
                })
            }
            else {
                handleError(dispatch)(response, null, 'EDIT LOYALTY GIFT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'EDIT LOYALTY GIFT ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_LOYALTY_GIFT_ENDED
        })
    },
    deleteGift: (ruuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_LOYALTY_GIFT_STARTED
        })
        await deleteGift(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'DELETE LOYALTY GIFT SUCCESS')
                dispatch({
                    type: GET_LOYALTY_GIFTS_SUCCESS,
                    payload: response.data.loyaltyGifts
                })
                close()
            }
            else {
                handleError(dispatch)(response, null, 'DELETE LOYALTY GIFT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'DELETE LOYALTY GIFT ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_LOYALTY_GIFT_ENDED
        })
    },
    getRewardsBasedOnLevel: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantRewards
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_REWARDS_BASED_ON_LEVEL_REQUEST,
                payload: ruuid
            })
        await getRewardsBasedOnLevel(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_REWARDS_BASED_ON_LEVEL_SUCCESS,
                    payload: response.data.rewardsBasedOnLevel
                })
            else {
                handleError(dispatch)(response, GET_REWARDS_BASED_ON_LEVEL_ERROR, 'GET REWARDS BASED ON LEVEL ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'GET REWARDS BASED ON LEVEL ERROR')
                // dispatch({
                //     type: GET_REWARDS_BASED_ON_LEVEL_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getRewardBasedOnLevel: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().loyalty.selectedRestaurantReward
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_REWARD_BASED_ON_LEVEL_REQUEST,
                payload: ruuid
            })
        await getRewardBasedOnLevel(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_REWARD_BASED_ON_LEVEL_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_REWARD_BASED_ON_LEVEL_ERROR, 'GET REWARD BASED ON LEVEL ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'GET REWARD BASED ON LEVEL ERROR')
                // dispatch({
                //     type: GET_REWARD_BASED_ON_LEVEL_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addRewardBasedOnLevel: (ruuid, data, history) => async (dispatch) => {
        dispatch({
            type: ADD_REWARD_BASED_ON_LEVEL_STARTED
        })
        await addRewardBasedOnLevel(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'ADD REWARD BASED ON LEVEL SUCCESS')
                dispatch({
                    type: GET_REWARDS_BASED_ON_LEVEL_SUCCESS,
                    payload: response.data
                })
                history.push('/crm/loyalty/rewards/reward-level')
            }
            else {
                handleError(dispatch)(response, null, 'ADD REWARD BASED ON LEVEL ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'ADD REWARD BASED ON LEVEL ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_REWARD_BASED_ON_LEVEL_ENDED
        })
    },
    editRewardBasedOnlevel: (ruuid, uuid, data, history, setEditRewardBasedOnLevel) => async (dispatch) => {
        dispatch({
            type: EDIT_REWARD_BASED_ON_LEVEL_STARTED
        })
        await editRewardBasedOnLevel(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'EDIT REWARD BASED ON LEVEL SUCCESS')
                dispatch({
                    type: GET_REWARDS_BASED_ON_LEVEL_SUCCESS,
                    payload: response.data
                })
                history.push('/crm/loyalty/rewards/reward-level')
                setEditRewardBasedOnLevel()
            }
            else {
                handleError(dispatch)(response, null, 'EDIT REWARD BASED ON ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'EDIT REWARD BASED ON LEVEL ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_REWARD_BASED_ON_LEVEL_ENDED
        })
    },
    deleteRewardBasedOnLevel: (ruuid, uuid, setModalDelete) => async (dispatch) => {
        dispatch({
            type: DELETE_REWARD_BASED_ON_LEVEL_STARTED
        })
        await deleteRewardBasedOnLevel(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                setModalDelete(false)
                toast.success(response?.data?.message || 'DELETE REWARD BASED ON LEVEL SUCCESS')
                dispatch({
                    type: GET_REWARDS_BASED_ON_LEVEL_SUCCESS,
                    payload: response?.data?.rewardsBasedOnLevel
                })
            } else {
                handleError(dispatch)(response, null, 'DELETE REWARD BASED ON LEVEL ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'DELETE REWARD BASED ON LEVEL ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_REWARD_BASED_ON_LEVEL_ENDED
        })
    },
    setEditRewardBasedOnLevel: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_REWARD_BASED_ON_LEVEL,
            payload: data
        })
    },
    editLoyaltyPoints: (ruuid, uuid, data, onClose, setModalState) => async (dispatch) => {
        dispatch({
            type: EDIT_LOYALTY_POINTS_STARTED
        })
        await editLoyaltyPoints(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'EDIT LOYALTY POINTS SUCCESS')
                dispatch({
                    type: GET_LOYALTY_USERS_SUCCESS,
                    payload: { users: { ...response.data, loyaltyUser: response.data.loyaltyUsers } }
                })
                onClose()
                setModalState(false)
            }
            else {
                handleError(dispatch)(response, null, 'EDIT LOYALTY POINTS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'EDIT LOYALTY POINTS ERROR')
                // if (response.status === 401) {
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_LOYALTY_POINTS_ENDED
        })
    },
    clearAll: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_LOYALTY
        })
    }
}
export default actions

