import {
    GET_STRIPE_ACCOUNT_REQUEST
    , GET_STRIPE_ACCOUNT_SUCCESS
    , GET_STRIPE_ACCOUNT_ERROR, ADD_STRIPE_ACCOUNT_END, ADD_STRIPE_ACCOUNT_STARTED
} from "../actions/StripeActions"

const initialState = {
    loading: false,
    account: null,
    addLoading: false
}


function stripeAccount(state = initialState, action) {
    switch (action.type) {
        case GET_STRIPE_ACCOUNT_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                account: null
            })
        case GET_STRIPE_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                account: action.payload.account,
            })
        case GET_STRIPE_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                loading: false,
                account: null,
            })

        case ADD_STRIPE_ACCOUNT_STARTED:
            return Object.assign({}, state, {
                addLoading: true,
            })

        case ADD_STRIPE_ACCOUNT_END:
            return Object.assign({}, state, {
                addLoading: false,
            })

        default:
            return state
    }
}

export default stripeAccount
