import { getTodayReport, getClosedReport, getReportDates } from '../../config/config';
import { handleError } from '../../utils/utils'

export const SET_TOTAL_DRIVERS_CHECKED = 'SET_TOTAL_DRIVERS_CHECKED';
export const SET_TOTAL_SALES_CHECKED = 'SET_TOTAL_SALES_CHECKED';
export const SET_DISCOUNTS_CHECKED = 'SET_DISCOUNT_CHECKED';
export const SET_PROMOTIONS_CHECKED = 'SET_PROMOTIONS_CHECKED';
export const SET_TOTAL_STATUSES_CHECKED = 'SET_TOTAL_STATUSES_CHECKED';
export const SET_TAXES_CHECKED = 'SET_TAXES_CHECKED';
export const SET_NET_SALES_CHECKED = 'SET_NET_SALES_CHECKED';
export const SET_SALES_BY_ITEMS_CHECKED = 'SET_SALES_BY_ITEMS_CHECKED';
export const SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED = 'SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED';
export const SET_SALES_BY_WORKSTATION_CHECKED = 'SET_SALES_BY_WORKSTATION_CHECKED';
export const SET_SALES_BY_CATEGORIES_CHECKED = 'SET_SALES_BY_CATEGORIES_CHECKED';
export const SET_SALES_BY_GROUP_CHECKED = 'SET_SALES_BY_GROUP_CHECKED';
export const SET_SALES_BY_PAYMENTS_CHECKED = 'SET_SALES_BY_PAYMENTS_CHECKED';
export const SET_FIRST_ORDER_CHECKED = 'SET_FIRST_ORDER_CHECKED';
export const SET_LAST_ORDER_CHECKED = 'SET_LAST_ORDER_CHECKED';
export const SET_FILTER = 'SET_FILTER';

export const GET_DAILY_REPORT_REQUEST = 'GET_DAILY_REPORT_REQUEST'
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS'
export const GET_DAILY_REPORT_ERROR = 'GET_DAILY_REPORT_ERROR'

export const GET_DAILY_REPORT_DATES_REQUEST = 'GET_DAILY_REPORT_DATES_REQUEST'
export const GET_DAILY_REPORT_DATES_SUCCESS = 'GET_DAILY_REPORT_DATES_SUCCESS'
export const GET_DAILY_REPORT_DATES_ERROR = 'GET_DAILY_REPORT_DATES_ERROR'

export const CLEAR_DAILY_REPORT = 'CLEAR_DAILY_REPORT'
export const CLEAR_DAILY_REPORT_LOADING = 'CLEAR_DAILY_REPORT_LOADING'

const actions = {
  //SET TOTAL DRIVERS CHECKED
  setTotalDriversChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TOTAL_DRIVERS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET TOTAL SALES CHECKED
  setTotalSalesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TOTAL_SALES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET DISCOUNTS CHECKED
  setDiscountsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_DISCOUNTS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET PROMOTIONS CHECKED
  setPromotionsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_PROMOTIONS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET TOTAL STATUSES CHECKED
  setTotalStatusesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TOTAL_STATUSES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET TOTAL SALES CHECKED
  setTaxesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TAXES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET NET SALES CHECKED
  setNetSalesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_NET_SALES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY ITEMS CHECKED
  setSalesByItemsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_ITEMS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES SUMMARY BY EMPLOYEE CHECKED
  setSalesSummaryByEmployeeChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY WORKSTATION CHECKED
  setSalesByWorkstationChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_WORKSTATION_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY CATEGORIES CHECKED
  setSalesByCategoriesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_CATEGORIES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY GROUP CHECKED
  setSalesByGroupChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_GROUP_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY PAYMENTS CHECKED
  setSalesByPaymentsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_PAYMENTS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET FIRST ORDER CHECKED
  setFirstOrderChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_FIRST_ORDER_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET LAST ORDER CHECKED
  setLastOrderChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_LAST_ORDER_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET FILTER
  setFilter: (value) => (dispatch) => {
    dispatch({
      type: SET_FILTER,
      payload: {
        value
      }
    })
  },
  //GET DAILY REPORT
  getDailyReport: (ruuid, buuid, selectedDate, isForClosed, query) => async (dispatch) => {
    dispatch({
      type: GET_DAILY_REPORT_REQUEST
    })
    if (!isForClosed) {
      await getTodayReport(ruuid, buuid, selectedDate, query).then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DAILY_REPORT_SUCCESS,
            payload: response.data
          })
        } else handleError(dispatch)(response, GET_DAILY_REPORT_ERROR, 'GET DAILY REPORT ERROR')
      })
    } else {
      await getClosedReport(ruuid, buuid, selectedDate).then((response) => {
        if (response.status === 200) {
          console.log(response.data)
          dispatch({
            type: GET_DAILY_REPORT_SUCCESS,
            payload: response.data
          })
        } else handleError(dispatch)(response, GET_DAILY_REPORT_ERROR, 'GET REPORT ERROR')
      })
    }
  },
  //GET DAILY REPORT DATES
  getDailyReportDates: (ruuid, buuid) => async (dispatch) => {
    dispatch({
      type: GET_DAILY_REPORT_DATES_REQUEST
    })
    await getReportDates(ruuid, buuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DAILY_REPORT_DATES_SUCCESS,
          payload: response?.data?.reports
        })
      } else handleError(dispatch)(response, GET_DAILY_REPORT_DATES_ERROR, 'GET REPORT DATES ERROR')
    })
  },
  //CLEAR DAILY REPORT
  clearDailyReport: () => (dispatch) => {
    dispatch({
      type: CLEAR_DAILY_REPORT
    })
  },
  //CLEAR DAILY REPORT LOADING
  clearDailyReportLoading: () => (dispatch) => {
    dispatch({
      type: CLEAR_DAILY_REPORT_LOADING
    })
  }
}

export default actions
