import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  SET_SELECTED_PROD,
  CLEAR_ALL_PRODUCT,
  ADD_PRODUCT_STARTED,
  ADD_PRODUCT_END,
  EDIT_PRODUCT_STARTED,
  EDIT_PRODUCT_END,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_END,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
  SET_ADD_PRODUCT_MODAL,
  SET_EDIT_PRODUCT_MODAL,
  EDIT_PRODUCT_IMAGE_STARTED,
  EDIT_PRODUCT_IMAGE_END,
  DELETE_PRODUCT_IMAGE_STARTED,
  DELETE_PRODUCT_IMAGE_END,
  EDIT_BULK_PRODUCTS_STARTED,
  EDIT_BULK_PRODUCTS_END,
  CLEAR_PRODUCTS_LOADING,
  GET_REWARDS_PRODUCTS_REQUEST,
  GET_REWARDS_PRODUCTS_SUCCESS,
  GET_REWARDS_PRODUCTS_ERROR,
  GET_PRODUCTS_ADD_EDIT_REQUEST,
  GET_PRODUCTS_ADD_EDIT_SUCCESS,
  GET_PRODUCTS_ADD_EDIT_ERROR,
  UPDATE_PRODUCT_STARTED,
  UPDATE_PRODUCT_END,
  GET_TYPES_AND_ALLERGIES_ERROR,
  GET_TYPES_AND_ALLERGIES_REQUEST,
  GET_TYPES_AND_ALLERGIES_SUCCESS
} from '../actions/ProductActions'


const initialState = {
  addEditProductsLoading: false,
  addEditProducts: [],
  typesAndAllergies: [],
  rewardsLoading: false,
  productRewards: [],
  loading: false,
  products: [],
  product: {},
  productLoading: false,
  editProduct: {
    open: false,
    data: null,
  },
  addProductOpen: false,
  selectedProd: '',
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  bulkLoading: false,
  selectedRestaurant: '',
  updateProductsLoading: false,
}

function prod(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_ADD_EDIT_ERROR:
      return Object.assign({}, state, {
        addEditProductsLoading: false
      })
    case GET_PRODUCTS_ADD_EDIT_SUCCESS:
      return Object.assign({}, state, {
        addEditProductsLoading: false,
        addEditProducts: action.payload.products
      })
    case GET_PRODUCTS_ADD_EDIT_REQUEST:
      return Object.assign({}, state, {
        addEditProductsLoading: true
      })
    case GET_REWARDS_PRODUCTS_ERROR:
      return Object.assign({}, state, {
        rewardsLoading: false,
        productRewards: []
      })
    case GET_REWARDS_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        rewardsLoading: false,
        productRewards: action.payload.products
      })
    case GET_REWARDS_PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        rewardsLoading: true
      })
    case CLEAR_PRODUCTS_LOADING:
      return Object.assign({}, state, {
        loading: false,
        productLoading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        bulkLoading: false,
      })
    case GET_PRODUCT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        products: action.payload.products,
      })
    case GET_PRODUCT_ERROR:
      return Object.assign({}, state, {
        loading: false,
        products: [],
      })

    case GET_PRODUCT_DETAILS_REQUEST:
      return Object.assign({}, state, {
        productLoading: true,
      })
    case GET_PRODUCT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        productLoading: false,
        product: action.payload.product,
      })
    case GET_PRODUCT_DETAILS_ERROR:
      return Object.assign({}, state, {
        productLoading: false,
        product: {},
      })

    case ADD_PRODUCT_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_PRODUCT_END:
      return Object.assign({}, state, {
        addLoading: false,
      })
      case UPDATE_PRODUCT_STARTED:
        return Object.assign({}, state, {
          updateProductsLoading: true,
        })
  
      case UPDATE_PRODUCT_END:
        return Object.assign({}, state, {
          updateProductsLoading: false,
        })

    case EDIT_PRODUCT_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_PRODUCT_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_PRODUCT_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_PRODUCT_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_SELECTED_PROD:
      return Object.assign({}, state, {
        selectedProd: action.payload.selected,
      })

    case CLEAR_ALL_PRODUCT:
      return initialState
    case SET_ADD_PRODUCT_MODAL:
      return Object.assign({}, state, {
        addProductOpen: action.payload,
      })

    case SET_EDIT_PRODUCT_MODAL:
      return Object.assign({}, state, {
        editProduct: action.payload,
      })

    case EDIT_PRODUCT_IMAGE_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_PRODUCT_IMAGE_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_PRODUCT_IMAGE_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_PRODUCT_IMAGE_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })
    case EDIT_BULK_PRODUCTS_STARTED:
      return Object.assign({}, state, {
        bulkLoading: true
      })
    case EDIT_BULK_PRODUCTS_END:
      return Object.assign({}, state, {
        bulkLoading: false
      })
    case GET_TYPES_AND_ALLERGIES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_TYPES_AND_ALLERGIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        typesAndAllergies: action.payload.typesAndAllergies,
      })
    case GET_TYPES_AND_ALLERGIES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        typesAndAllergies: [],
      })
    default:
      return state
  }
}

export default prod
