import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NAVIGATE_TO } from '../redux/actions/NavigationActions';

const NavigationHandler = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigateToPath = useSelector((state) => state.navigation.path);

  useEffect(() => {
    if (navigateToPath) {
      history.push(navigateToPath);
      dispatch({ type: NAVIGATE_TO, payload: null }); // Reset navigation action
    }
  }, [navigateToPath, history, dispatch]);

  return null;
};

export default NavigationHandler;
