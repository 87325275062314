import {
   GET_RESTAURANTS_DESIGN_SETUP_REQUEST,
   GET_RESTAURANTS_DESIGN_SETUP_SUCCESS,
   GET_RESTAURANTS_DESIGN_SETUP_ERROR,
   EDIT_RESTAURANTS_DESIGN_SETUP_STARTED,
   EDIT_RESTAURANTS_DESIGN_SETUP_ENDED
} from "../actions/RestaurantsDesignSetupActions";

const initialState = {
    getRestaurantsDesignSetupLoading: false,
    editRestaurantsDesignSetupLoading: false,
    restaurantsDesignSetup: null,
    mainDesignSetup: null
};

function restaurantsDesignSetup(state = initialState, action) {
    switch (action.type) {
        case GET_RESTAURANTS_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                getRestaurantsDesignSetupLoading: true
            })
        case GET_RESTAURANTS_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                getRestaurantsDesignSetupLoading: false,
                restaurantsDesignSetup: action?.payload?.restaurantsDesignSetup,
                mainDesignSetup: action?.payload?.mainDesignSetup
            })
        case GET_RESTAURANTS_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                getRestaurantsDesignSetupLoading: false,
                restaurantsDesignSetup: null,
            })
        case EDIT_RESTAURANTS_DESIGN_SETUP_STARTED:
            return Object.assign({}, state, {
                editRestaurantsDesignSetupLoading: true,
            })
        case EDIT_RESTAURANTS_DESIGN_SETUP_ENDED:
            return Object.assign({}, state, {
                editRestaurantsDesignSetupLoading: false,
            })
        default:
            return state;
    }
}

export default restaurantsDesignSetup;