import {
  getOrders,
  getOrder,
  cancelOrder,
  acceptOrder,
  rejectOrder,
  completeOrder,
  updateDispatchSettings,
  dispatchOrder,
  getDrivers,
  getLoyaltyConfig,
  undispatchOrder,
  undispatchAllOrders,
  setBusy,
  setClosed,
  getReasons,
  addReason,
  editReason,
  deleteReason,
  getDriver,
  setBusyAll,
  setClosedAll,
} from "../../config/config";
import { toast } from "react-toastify";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { handleError } from "../../utils/utils";
import { GET_DELIVERYZONES_SETTINGS_SUCCESS } from "./DeliveryZoneActions";

export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const INCREMENT_PAGE_NUMBER = "INCREMENT_PAGE_NUMBER";
export const SET_ORDERS = "SET-ORDERS";
export const ADD_NEW_ORDERS = "ADD_NEW_ORDERS";
export const SET_NEW_ORDERS = "SET_NEW_ORDERS";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const GET_ORDERS_DISPATCH_REQUEST = "GET_ORDERS_DISPATCH_REQUEST";
export const GET_ORDERS_DISPATCH_SUCCESS = "GET_ORDERS_DISPATCH_SUCCESS";
export const GET_ORDERS_DISPATCH_ERROR = "GET_ORDERS_DISPATCH_ERROR";

export const SET_ORDERS_DISPATCH = "SET_ORDERS_DISPATCH"

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_REASONS_REQUEST = "GET_REASONS_REQUEST";
export const GET_REASONS_SUCCESS = "GET_REASONS_SUCCESS";
export const GET_REASONS_ERROR = "GET_REASONS_ERROR";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";

export const ACCEPT_ORDER_REQUEST = "ACCEPT_ORDER_REQUEST";
export const ACCEPT_ORDER_SUCCESS = "ACCEPT_ORDER_SUCCESS";
export const ACCEPT_ORDER_ERROR = "ACCEPT_ORDER_ERROR";

export const REJECT_ORDER_REQUEST = "REJECT_ORDER_REQUEST";
export const REJECT_ORDER_SUCCESS = "REJECT_ORDER_SUCCESS";
export const REJECT_ORDER_ERROR = "REJECT_ORDER_ERROR";

export const COMPLETE_ORDER_REQUEST = "COMPLETE_ORDER_REQUEST";
export const COMPLETE_ORDER_SUCCESS = "COMPLETE_ORDER_SUCCESS";
export const COMPLETE_ORDER_ERROR = "COMPLETE_ORDER_ERROR";

export const RESET_ORDERS = "RESET_ORDERS";
export const RESET_ORDERS_FILTERS = "RESET_ORDERS_FILTERS";

export const SET_ORDERS_SEARCH_VALUE = "SET_ORDERS_SEARCH_VALUE";

export const SET_ORDERS_VIEW = "SET_ORDERS_VIEW";
export const SET_ORDERS_GROUP = "SET_ORDERS_GROUP";
export const SET_ACTIVE_ORDERS_FILTER = "SET_ACTIVE_ORDERS_FILTER";
export const SET_IS_FILTER = "SET_IS_FILTER";
export const SET_ORDERS_FILTER = "SET_ORDERS_FILTER";
export const SET_ORDERS_GRID_SORT_KEY = "SET_ORDERS_GRID_SORT_KEY"
export const SET_ORDERS_GRID_SORTING_ORDER = "SET_ORDERS_GRID_SORTING_ORDER"

export const DISPATCH_ORDERS_STARTED = 'DISPATCH_ORDERS_STARTED'
export const DISPATCH_ORDERS_ENDED = 'DISPATCH_ORDERS_ENDED'

export const EDIT_DISPATCH_SETTINGS_STARTED = 'EDIT_DISPATCH_SETTINGS_STARTED'
export const EDIT_DISPATCH_SETTINGS_ENDED = 'EDIT_DISPATCH_SETTINGS_ENDED'

export const GET_DRIVERS_REQUEST = "GET_DRIVERS_REQUEST";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_ERROR = "GET_DRIVERS_ERROR";

export const GET_DRIVER_REQUEST = "GET_DRIVER_REQUEST";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";

export const GET_DISPATCH_CONFIG_REQUEST = 'GET_DISPATCH_CONFIG_REQUEST'
export const GET_DISPATCH_CONFIG_SUCCESS = 'GET_DISPATCH_CONFIG_SUCCESS'
export const GET_DISPATCH_CONFIG_ERROR = 'GET_DISPATCH_CONFIG_ERROR'

export const GET_DISPATCH_CONFIG_ALL_REQUEST = 'GET_DISPATCH_CONFIG_ALL_REQUEST'
export const GET_DISPATCH_CONFIG_ALL_SUCCESS = 'GET_DISPATCH_CONFIG_ALL_SUCCESS'
export const GET_DISPATCH_CONFIG_ALL_ERROR = 'GET_DISPATCH_CONFIG_ALL_ERROR'

export const UNDISPATCH_STARTED = 'UNDISPATCH_STARTED'
export const UNDISPATCH_ENDED = 'UNDISPATCH_ENDED'

export const UNDISPATCH_ALL_STARTED = 'UNDISPATCH_ALL_STARTED'
export const UNDISPATCH_ALL_ENDED = 'UNDISPATCH_ALL_ENDED'

export const SET_BUSY_STARTED = 'SET_BUSY_STARTED'
export const SET_BUSY_ENDED = 'SET_BUSY_ENDED'

export const SET_CLOSED_STARTED = 'SET_CLOSED_STARTED'
export const SET_CLOSED_ENDED = 'SET_CLOSED_ENDED'

export const CLEAR_ORDER_LOADING = 'CLEAR_ORDER_LOADING'
export const CLEAR_GET_ORDERS_LOADING = 'CLEAR_GET_ORDERS_LOADING'

export const SET_BUSY_SETTINGS = 'SET_BUSY_SETTINGS'
export const SET_CLOSED_SETTINGS = 'SET_CLOSED_SETTINGS'

export const SET_BUSY_SETTINGS_ALL = 'SET_BUSY_SETTINGS_ALL'
export const SET_CLOSED_SETTINGS_ALL = 'SET_CLOSED_SETTINGS_ALL'

export const ADD_REASON_STARTED = 'ADD_REASON_STARTED'
export const ADD_REASON_ENDED = 'ADD_REASON_ENDED'

export const EDIT_REASON_STARTED = 'EDIT_REASON_STARTED'
export const EDIT_REASON_ENDED = 'EDIT_REASON_ENDED'

export const DELETE_REASON_STARTED = 'DELETE_REASON_STARTED'
export const DELETE_REASON_ENDED = 'DELETE_REASON_ENDED'

export const SET_DRIVER = 'SET_DRIVER'

export const SET_SELECTED_RESTAURANTS = 'SET_SELECTED_RESTAURANTS'
export const SET_SELECTED_BRANCHES = 'SET_SELECTED_BRANCHES'
export const SET_BRANCHES_NEEDED = 'SET_BRANCHES_NEEDED'

const actions = {
  setDispatchOrders: (data) => async (dispatch) => {
    dispatch({
      type: SET_ORDERS_DISPATCH,
      payload: data
    })
  },
  setBranchesNeeded: (data) => async (dispatch) => {
    dispatch({
      type: SET_BRANCHES_NEEDED,
      payload: data
    })
  },
  setSelectedBranches: (data) => async (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCHES,
      payload: data
    })
  },
  setSelectedRestaurants: (data) => async (dispatch) => {
    dispatch({
      type: SET_SELECTED_RESTAURANTS,
      payload: data
    })
  },
  setDriver: (data) => async (dispatch) => {
    dispatch({
      type: SET_DRIVER,
      payload: data
    })
  },
  getReasons: () => async (dispatch) => {
    dispatch({
      type: GET_REASONS_REQUEST
    })
    await getReasons().then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_REASONS_SUCCESS,
          payload: response.data.reasons
        })
      else handleError(dispatch)(response, GET_REASONS_ERROR, 'GET REASONS ERROR')
    })
  },
  addReason: (data, close) => async (dispatch) => {
    dispatch({
      type: ADD_REASON_STARTED
    })
    await addReason(data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'ADD REASON SUCCESS')
        dispatch({
          type: GET_REASONS_SUCCESS,
          payload: response.data.reasons
        })
        if (close)
          close()
      }
      else handleError(dispatch)(response, null, 'ADD REASON ERROR')
    })
    dispatch({
      type: ADD_REASON_ENDED
    })
  },
  editReason: (uuid, data, close) => async (dispatch) => {
    dispatch({
      type: EDIT_REASON_STARTED
    })
    await editReason(uuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'EDIT REASON SUCCESS')
        dispatch({
          type: GET_REASONS_SUCCESS,
          payload: response.data.reasons
        })
        if (close)
          close()
      }
      else handleError(dispatch)(response, null, 'EDIT REASON ERROR')
    })
    dispatch({
      type: EDIT_REASON_ENDED
    })
  },
  deleteReason: (uuid, close) => async (dispatch) => {
    dispatch({
      type: DELETE_REASON_STARTED
    })
    await deleteReason(uuid).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'DELETE REASON SUCCESS')
        dispatch({
          type: GET_REASONS_SUCCESS,
          payload: response.data.reasons
        })
        if (close)
          close()
      }
      else handleError(dispatch)(response, null, 'DELETE REASON ERROR')
    })
    dispatch({
      type: DELETE_REASON_ENDED
    })
  },
  setClosed: (data, setClosedModal, query) => async (dispatch, getState) => {
    dispatch({
      type: SET_CLOSED_STARTED
    })
    let closedSettings = getState().order.closedSettingsAll
    await setClosedAll(data, query).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'SET CLOSED SUCCESS')
        response.data.onlineDeliverySetting.map((obj) => {
          let index = closedSettings.findIndex((obj2) => obj2.branch.uuid === obj.branch.uuid)
          if (index !== -1)
            closedSettings[index] = {
              isClosed: obj.isClosed,
              closedDateStart: obj.closedDateStart,
              closedDateEnd: obj.closedDateEnd,
              branch: obj.branch
            }
        })

        dispatch({
          type: SET_CLOSED_SETTINGS_ALL,
          payload: [...closedSettings]
        })
        setClosedModal()
      } else handleError(dispatch)(response, null, 'SET CLOSED ERROR')
    })
    dispatch({
      type: SET_CLOSED_ENDED
    })
  },
  setBusy: (data, setIsBusy, query) => async (dispatch, getState) => {
    dispatch({
      type: SET_BUSY_STARTED
    })
    let busySettings = getState().order.busySettingsAll
    await setBusyAll(data, query).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'SET BUSY SUCCESS')
        response.data.onlineDeliverySetting.map((obj) => {
          let index = busySettings.findIndex((obj2) => obj2.branch.uuid === obj.branch.uuid)
          if (index !== -1)
            busySettings[index] = {
              delayPeriod: obj.delayPeriod,
              busyDateStart: obj.busyDateStart,
              busyDateEnd: obj.busyDateEnd,
              branch: obj.branch
            }
        })
        dispatch({
          type: SET_BUSY_SETTINGS_ALL,
          payload: [...busySettings]
        })
        setIsBusy()
      } else handleError(dispatch)(response, null, 'SET BUSY ERROR')
    })
    dispatch({
      type: SET_BUSY_ENDED
    })
  },
  undispatchAll: (ruuid, bruuid, duuid, getDriver, successCb, filterUrl) => async (dispatch) => {
    dispatch({
      type: UNDISPATCH_ALL_STARTED
    })
    await undispatchAllOrders(ruuid, bruuid, duuid, filterUrl).then(async (response) => {
      if (response.status === 200) {
        // toast.success(response.data.message || 'UNDISPATCH ALL SUCCESS')
        // await getOrders(ruuid, bruuid).then((response) => {
        //   if (response?.status === 200) {
        //     dispatch({
        //       type: GET_ORDERS_SUCCESS,
        //       payload: {
        //         orders: response?.data?.orders,
        //       },
        //     });
        //   } else
        //     handleError(dispatch)(response, GET_ORDERS_ERROR, 'GET ORDERS ERROR')
        // })
        getDriver(duuid)
        if (successCb)
          successCb()
      }
    })
    dispatch({
      type: UNDISPATCH_ALL_ENDED
    })
  },
  undispatch: (ruuid, bruuid, duuid, ouuid, getDriver, successCb) => async (dispatch) => {
    dispatch({
      type: UNDISPATCH_STARTED,
      payload: {
        ouuid: ouuid
      }
    })
    await undispatchOrder(ruuid, bruuid, duuid.uuid || duuid, ouuid.uuid || ouuid).then(async (response) => {
      if (response.status === 200) {
        // toast.success(response.data.message || 'UNDISPATCHED SUCCESSFULLY')
        // await getOrders(ruuid, bruuid).then((response) => {
        //   if (response?.status === 200) {
        //     dispatch({
        //       type: GET_ORDERS_SUCCESS,
        //       payload: {
        //         orders: response?.data?.orders,
        //       },
        //     });
        //   } else
        //     handleError(dispatch)(response, GET_ORDERS_ERROR, 'GET ORDERS ERROR')
        // })
        getDriver(duuid.uuid || duuid)
        if (successCb)
          successCb()
      } else handleError(dispatch)(response, null, 'UNDISPATCH ERROR')
    })
    dispatch({
      type: UNDISPATCH_ENDED
    })
  },
  getDispatchConfig: (ruuid) => async (dispatch) => {
    dispatch({
      type: GET_DISPATCH_CONFIG_REQUEST
    })
    await getLoyaltyConfig(ruuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DISPATCH_CONFIG_SUCCESS,
          payload: response.data.generalConfigurations.find((config) => config.module === 'dispatchConfig')
        })
      } else handleError(dispatch)(response, GET_DISPATCH_CONFIG_ERROR, 'GET DISPATCH CONFIG ERROR')
    })
  },
  getDispatchConfigAll: () => async (dispatch) => {
    dispatch({
      type: GET_DISPATCH_CONFIG_ALL_REQUEST
    })
    await getLoyaltyConfig().then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DISPATCH_CONFIG_ALL_SUCCESS,
          payload: response.data.generalConfigurations.filter((config) => config.module === 'dispatchConfig')
        })
      } else handleError(dispatch)(response, GET_DISPATCH_CONFIG_ALL_ERROR, 'GET DISPATCH CONFIG ERROR')
    })
  },
  getDrivers: (ruuid, bruuid) => async (dispatch) => {
    dispatch({
      type: GET_DRIVERS_REQUEST
    })
    await getDrivers(ruuid, bruuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DRIVERS_SUCCESS,
          payload: response.data.drivers
        })
      } else handleError(dispatch)(response, GET_DRIVERS_ERROR, 'GET DRIVERS ERROR')
    })
  },
  getDriver: (ruuid, bruuid, uuid) => async (dispatch) => {
    dispatch({
      type: GET_DRIVER_REQUEST
    })
    await getDriver(ruuid, bruuid, uuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DRIVER_SUCCESS,
          payload: response.data.driver
        })
      } else handleError(dispatch)(response, GET_DRIVER_ERROR, 'GET DRIVER ERROR')
    })
  },
  dispatchOrders: (ruuid, bruuid, data, successCb = null, getDriver, duuid) => async (dispatch) => {
    dispatch({
      type: DISPATCH_ORDERS_STARTED
    })
    await dispatchOrder(ruuid, bruuid, data).then(async (response) => {
      if (response.status === 200) {
        // toast.success(response.data.message || 'DISPATCHED SUCCESSFULLY')
        // await getOrders(ruuid, bruuid).then((response) => {
        //   if (response?.status === 200) {
        //     dispatch({
        //       type: GET_ORDERS_SUCCESS,
        //       payload: {
        //         orders: response?.data?.orders,
        //       },
        //     });
        //     if (successCb) {
        //       successCb();
        //     }
        //   } else
        //     handleError(dispatch)(response, GET_ORDERS_ERROR, 'GET ORDERS ERROR')
        // })
        // await getDrivers(ruuid, bruuid).then((response) => {
        //   if (response.status === 200) {
        //     dispatch({
        //       type: GET_DRIVERS_SUCCESS,
        //       payload: response.data.drivers
        //     })
        //   } else handleError(dispatch)(response, GET_DRIVERS_ERROR, 'GET DRIVERS ERRRO')
        // })
        if (getDriver && duuid) {
          getDriver(duuid);
        }
        if (successCb) {
          successCb();
        }
        dispatch({
          type: DISPATCH_ORDERS_ENDED
        })
      } else {
        handleError(dispatch)(response, null, 'DISPATCH ERROR');
        dispatch({
          type: DISPATCH_ORDERS_ENDED
        })
      }
    })
  },
  updateDispatchSettings: (ruuid, data, onClose) => async (dispatch) => {
    dispatch({
      type: EDIT_DISPATCH_SETTINGS_STARTED
    })
    await updateDispatchSettings(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'UPDATE DISPATCH SETTINGS SUCCESS')
        dispatch({
          type: GET_DISPATCH_CONFIG_SUCCESS,
          payload: response.data.generalConfigurations.find((config) => config.module === 'dispatchConfig')
        })
        onClose()
      } else handleError(dispatch)(response, null, 'UPDATE DISPATCH SETTINGS ERROR')
    })
    dispatch({
      type: EDIT_DISPATCH_SETTINGS_ENDED
    })
  },
  setOrders: (data) => (dispatch) => {
    dispatch({
      type: SET_ORDERS,
      payload: {
        data: data
      }
    });
  },
  addNewOrders: (newOrders, extraOrdersOnPageOne) => {
    return (dispatch) => {
      dispatch({
        type: ADD_NEW_ORDERS,
        payload: {
          newOrders: newOrders,
          extraOrdersOnPageOne: extraOrdersOnPageOne,
        },
      });
    };
  },
  setNewOrders: (value) => (dispatch) => {
    dispatch({
      type: SET_NEW_ORDERS,
      payload: {
        value: value
      }
    })
  },
  incrementPageNumber: () => (dispatch) => {
    dispatch({
      type: INCREMENT_PAGE_NUMBER
    })
  },
  setPageNumber: (value) => (dispatch) => {
    dispatch({
      type: SET_PAGE_NUMBER,
      payload: {
        value: value
      }
    })
  },
  getOrders: (ruuid, buuid, pageNumber, limit, filterUrl, cancelToken = null) => async (dispatch) => {
    dispatch({
      type: GET_ORDERS_REQUEST,
    });
    await getOrders(ruuid, buuid, pageNumber, limit, filterUrl, cancelToken).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: {
            newOrders: response?.data?.orders,
            hasMore: pageNumber < response?.data?.totalPages,
            totalRecords: response?.data?.totalRecords
          },
        });
      } else {
        handleError(dispatch)(response, GET_ORDERS_ERROR, 'GET ORDERS ERROR')
      }
    });
  },
  getOrdersDispatch: (ruuid, buuid, pageNumber, limit, filterUrl, cancelToken = null) => async (dispatch) => {
    dispatch({
      type: GET_ORDERS_DISPATCH_REQUEST,
    });
    await getOrders(ruuid, buuid, pageNumber, limit, filterUrl, cancelToken).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: GET_ORDERS_DISPATCH_SUCCESS,
          payload: {
            newOrders: response?.data?.orders,
            hasMore: pageNumber < response?.data?.totalPages,
            totalRecords: response?.data?.totalRecords
          },
        });
      } else {
        handleError(dispatch)(response, GET_ORDERS_DISPATCH_ERROR, 'GET ORDERS DISPATCH ERROR')
      }
    });
  },
  getOrder: (ruuid, buuid, ouuid) => async (dispatch) => {
    dispatch({
      type: GET_ORDER_REQUEST,
    });
    await getOrder(ruuid, buuid, ouuid).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: {
            order: response?.data?.order,
          },
        });
      } else {
        handleError(dispatch)(response, GET_ORDER_ERROR, 'GET ORDER ERROR')
        // dispatch({
        //   type: GET_ORDER_ERROR,
        // });
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Get Order error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions,
        //   });
        // }
      }
    });
  },
  cancelOrder: (ruuid, buuid, order, successCb = null, data, close) => async (dispatch) => {
    const wasOrderAccepted = order?.statusKey === "accepted" || order?.statusKey === "deliverd" || order?.statusKey === "picked-up";

    dispatch({
      type: CANCEL_ORDER_REQUEST,
    });
    await cancelOrder(ruuid, buuid, order?.uuid, wasOrderAccepted, data).then((response) => {
      if (response?.status === 200) {
        // toast.success(response.data.message || 'ORDER CANCELED')
        dispatch({
          type: CANCEL_ORDER_SUCCESS,
          payload: {
            ouuid: order?.uuid,
          },
        });
        if (successCb) {
          successCb(order?.uuid, "cancel");
        }
        if (close)
          close()
      } else {
        handleError(dispatch)(response, CANCEL_ORDER_ERROR, 'CANCEL ORDER ERROR')
        // dispatch({
        //   type: CANCEL_ORDER_ERROR,
        // });
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Cancel Order error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions,
        //   });
        // }
      }
    });
  },
  acceptOrder: (ruuid, buuid, ouuid, successCb) => async (dispatch) => {
    dispatch({
      type: ACCEPT_ORDER_REQUEST,
    });
    await acceptOrder(ruuid, buuid, ouuid).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: ACCEPT_ORDER_SUCCESS,
          payload: {
            ouuid: ouuid,
          },
        });
        if (successCb) {
          successCb(ouuid, "accept");
        }
      } else {
        handleError(dispatch)(response, ACCEPT_ORDER_ERROR, 'ACCEPT ORDER ERROR')
        // dispatch({
        //   type: ACCEPT_ORDER_ERROR,
        // });
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Accept Order error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions,
        //   });
        // }
      }
    });
  },
  rejectOrder: (ruuid, buuid, order, successCb, data, close) => async (dispatch) => {
    const wasOrderAccepted = order?.statusKey === "accepted" || order?.statusKey === "deliverd" || order?.statusKey === "picked-up";

    dispatch({
      type: REJECT_ORDER_REQUEST,
    });

    await rejectOrder(ruuid, buuid, order?.uuid, wasOrderAccepted, data).then((response) => {
      if (response?.status === 200) {
        // toast.success(response.data.message || 'ORDER REJECTED')
        dispatch({
          type: REJECT_ORDER_SUCCESS,
          payload: {
            ouuid: order?.uuid,
          },
        });

        if (successCb) {
          successCb(order?.uuid, "reject");
        }
        if (close)
          close()
      } else {
        handleError(dispatch)(response, REJECT_ORDER_ERROR, 'REJECT ORDER ERROR')
        // dispatch({
        //   type: REJECT_ORDER_ERROR,
        // });
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Reject Order error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions,
        //   });
        // }
      }
    });
  },
  completeOrder: (ruuid, buuid, ouuid, successCb = null) => async (dispatch) => {
    dispatch({
      type: COMPLETE_ORDER_REQUEST,
    });
    await completeOrder(ruuid, buuid, ouuid).then((response) => {
      if (response?.status === 200) {
        // toast.success(response.data.message || 'ORDER COMPLETED')
        dispatch({
          type: COMPLETE_ORDER_SUCCESS,
          payload: {
            ouuid: ouuid,
          },
        });

        if (successCb) {
          successCb(ouuid, "complete");
        }
      } else {
        handleError(dispatch)(response, COMPLETE_ORDER_ERROR, 'COMPLETE ORDER ERROR')
        // dispatch({
        //   type: COMPLETE_ORDER_ERROR,
        // });
        // toast.error(response?.data?.message || "Complete Order error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions,
        //   });
      }
    });
  },
  clearGetOrdersLoading: () => (dispatch) => {
    dispatch({
      type: CLEAR_GET_ORDERS_LOADING
    });
  },
  resetOrders: () => (dispatch) => {
    dispatch({
      type: RESET_ORDERS,
    });
  },
  resetOrdersFilters: () => (dispatch) => {
    dispatch({
      type: RESET_ORDERS_FILTERS
    });
  },
  setOrdersSearchValue: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_SEARCH_VALUE,
      payload: {
        value: value
      }
    })
  },
  setOrdersView: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_VIEW,
      payload: {
        value: value
      }
    })
  },
  setOrdersGroup: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_GROUP,
      payload: {
        value: value
      }
    })
  },
  setActiveOrdersFilter: (value) => (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ORDERS_FILTER,
      payload: {
        value: value
      }
    })
  },
  setIsFilter: (value) => (dispatch) => {
    dispatch({
      type: SET_IS_FILTER,
      payload: {
        value: value
      }
    })
  },
  setOrdersFilter: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_FILTER,
      payload: {
        value: value
      }
    })
  },
  setOrdersGridSortKey: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_GRID_SORT_KEY,
      payload: {
        value: value
      }
    })
  },
  setOrdersGridSortingOrder: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_GRID_SORTING_ORDER,
      payload: {
        value: value
      }
    })
  }
};

export default actions;
