import {
    ADD_QR_STARTED,
    ADD_QR_ENDED,

    ADD_QR_TABLES_STARTED,
    ADD_QR_TABLES_ENDED,

    ADD_QR_BUTTON_STARTED,
    ADD_QR_BUTTON_ENDED,

    EDIT_QR_BUTTON_STARTED,
    EDIT_QR_BUTTON_ENDED,

    DELETE_QR_BUTTON_STARTED,
    DELETE_QR_BUTTON_ENDED,

    DELETE_QR_TABLE_STARTED,
    DELETE_QR_TABLE_ENDED,

    GET_QR_BUTTON_REQUEST,
    GET_QR_BUTTON_SUCCESS,
    GET_QR_BUTTON_ERROR,

    GET_QR_BUTTONS_REQUEST,
    GET_QR_BUTTONS_SUCCESS,
    GET_QR_BUTTONS_ERROR,

    GET_TABLE_ORDERING_SETTINGS_REQUEST,
    GET_TABLE_ORDERING_SETTINGS_SUCCESS,
    GET_TABLE_ORDERING_SETTINGS_ERROR,

    ADD_EDIT_TABLE_ORDERING_SETTINGS_STARTED,
    ADD_EDIT_TABLE_ORDERING_SETTINGS_ENDED,

    GET_QR_REQUEST,
    GET_QR_SUCCESS,
    GET_QR_ERROR,

    GET_QR_TABLES_REQUEST,
    GET_QR_TABLES_SUCCESS,
    GET_QR_TABLES_ERROR,

    VALIDATE_URL_STARTED,
    VALIDATE_URL_ENDED,
    VALIDATE_URL_SUCCESS,
    CLEAR_ALL_QR,

    GET_ONLINE_DELIVERY_QR_REQUEST,
    GET_ONLINE_DELIVERY_QR_SUCCESS,
    GET_ONLINE_DELIVERY_QR_ERROR,

    ADD_ONLINE_DELIVERY_QR_STARTED,
    ADD_ONLINE_DELIVERY_QR_ENDED,

    SET_QR_SETTINGS_MODAL_STATE,
    CLEAR_QR_LOADING,

    //entity Qr
    GET_ENTITY_QR_REQUEST,
    GET_ENTITY_QR_SUCCESS,
    GET_ENTITY_QR_ERROR,

    ADD_EDIT_ENTITY_QR_STARTED,
    ADD_EDIT_ENTITY_QR_ENDED,

    //entity Qr tables
    GET_ENTITY_QR_TABLES_REQUEST,
    GET_ENTITY_QR_TABLES_SUCCESS,
    GET_ENTITY_QR_TABLES_ERROR,

    ADD_ENTITY_QR_TABLES_STARTED,
    ADD_ENTITY_QR_TABLES_ENDED,
    
    DELETE_ENTITY_QR_TABLE_STARTED,
    DELETE_ENTITY_QR_TABLE_ENDED,
    
    //entity Qr buttons
    GET_ENTITY_QR_BUTTON_REQUEST,
    GET_ENTITY_QR_BUTTON_SUCCESS,
    GET_ENTITY_QR_BUTTON_ERROR,

    GET_ENTITY_QR_BUTTONS_REQUEST,
    GET_ENTITY_QR_BUTTONS_SUCCESS,
    GET_ENTITY_QR_BUTTONS_ERROR,

    ADD_ENTITY_QR_BUTTON_STARTED,
    ADD_ENTITY_QR_BUTTON_ENDED,

    EDIT_ENTITY_QR_BUTTON_STARTED,
    EDIT_ENTITY_QR_BUTTON_ENDED,

    DELETE_ENTITY_QR_BUTTON_STARTED,
    DELETE_ENTITY_QR_BUTTON_ENDED
} from '../actions/QRActions'

const initialState = {
    getQrloading: false,
    addQrLoading: false,
    editQrLoading: false,
    deleteQrLoading: false,
    getQrOnlineDeliveryLoading: false,
    addQrOnlineDeliveryLoading: false,
    editQrOnlineDeliveryLoading: false,
    deleteQrOnlineDeliveryLoading: false,
    getQrTablesLoading: false,
    deleteQrTablesLoading: false,
    addQrTablesLoading: false,
    getQrButtonsLoading: false,
    addQrButtonLoading: false,
    editQrButtonLoading: false,
    deleteQrButtonLoading: false,
    getQrButtonLoading: false,
    validateLoading: false,
    qr: null,
    qrOnlineDelivery: null,
    tables: [],
    buttons: [],
    button: {},
    validatedUrl: {},
    selectedRestaurantQR: '',
    selectedRestaurantQROnlineDelivery: '',
    selectedBranchQR: '',
    selectedRestaurantTables: '',
    selectedBranchTables: '',
    selectedRestaurantButtons: '',
    selectedBranchButtons: '',
    selectedBranchSettings: '',
    QRSettingsModalState: false,
    settings: null,
    settingsLoading: false,
    addSettingsLoading: false,
    //entity qr
    getEntityQrLoading: false,
    addEditEntityQrLoading: false,
    deleteEntityQrLoading: false,
    getEntityQrTablesLoading: false,
    deleteEntityQrTablesLoading: false,
    addEntityQrTablesLoading: false,
    getEntityQrButtonsLoading: false,
    addEntityQrButtonLoading: false,
    editEntityQrButtonLoading: false,
    deleteEntityQrButtonLoading: false,
    getEntityQrButtonLoading: false,
    entityQr: null,
    entityTables: [],
    entityButtons: [],
    entityButton: {},
    selectedEntityQr: '',
    selectedEntityTables: '',
    selectedEntityButtons: ''
}

function qr(state = initialState, action) {
    switch (action.type) {
        case CLEAR_QR_LOADING:
            return Object.assign({}, state, {
                getQrloading: false,
                addQrLoading: false,
                editQrLoading: false,
                deleteQrLoading: false,
                getQrOnlineDeliveryLoading: false,
                addQrOnlineDeliveryLoading: false,
                editQrOnlineDeliveryLoading: false,
                deleteQrOnlineDeliveryLoading: false,
                getQrTablesLoading: false,
                deleteQrTablesLoading: false,
                addQrTablesLoading: false,
                getQrButtonsLoading: false,
                addQrButtonLoading: false,
                editQrButtonLoading: false,
                deleteQrButtonLoading: false,
                getQrButtonLoading: false,
                validateLoading: false,
                settingsLoading: false,
                addSettingsLoading: false,
                getEntityQrLoading: false,
                addEntityQrLoading: false,
                editEntityQrLoading: false,
                deleteEntityQrLoading: false,
                getEntityQrTablesLoading: false,
                deleteEntityQrTablesLoading: false,
                addEntityQrTablesLoading: false,
                getEntityQrButtonsLoading: false,
                addEntityQrButtonLoading: false,
                editEntityQrButtonLoading: false,
                deleteEntityQrButtonLoading: false,
                getEntityQrButtonLoading: false,
            })
        case GET_ONLINE_DELIVERY_QR_REQUEST:
            return Object.assign({}, state, {
                getQrOnlineDeliveryLoading: true
            })
        case GET_ONLINE_DELIVERY_QR_SUCCESS:
            return Object.assign({}, state, {
                getQrOnlineDeliveryLoading: false,
                qrOnlineDelivery: action.payload
            })
        case GET_ONLINE_DELIVERY_QR_ERROR:
            return Object.assign({}, state, {
                getQrOnlineDeliveryLoading: false,
                qrOnlineDelivery: null
            })
        case ADD_ONLINE_DELIVERY_QR_STARTED:
            return Object.assign({}, state, {
                addQrOnlineDeliveryLoading: true
            })
        case ADD_ONLINE_DELIVERY_QR_ENDED:
            return Object.assign({}, state, {
                addQrOnlineDeliveryLoading: false
            })
        case GET_TABLE_ORDERING_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                settingsLoading: true,
                selectedBranchButtons: action.payload
            })
        case GET_TABLE_ORDERING_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                settingsLoading: false,
                settings: action.payload
            })
        case GET_TABLE_ORDERING_SETTINGS_ERROR:
            return Object.assign({}, state, {
                settingsLoading: false,
                settings: null
            })
        case ADD_EDIT_TABLE_ORDERING_SETTINGS_STARTED:
            return Object.assign({}, state, {
                addSettingsLoading: true
            })
        case ADD_EDIT_TABLE_ORDERING_SETTINGS_ENDED:
            return Object.assign({}, state, {
                addSettingsLoading: false
            })
        case CLEAR_ALL_QR:
            return initialState
        case SET_QR_SETTINGS_MODAL_STATE:
            return Object.assign({}, state, {
                QRSettingsModalState: action.payload
            })
        case ADD_QR_STARTED:
            return Object.assign({}, state, {
                addQrLoading: true
            })
        case ADD_QR_ENDED:
            return Object.assign({}, state, {
                addQrLoading: false
            })
        case ADD_QR_TABLES_STARTED:
            return Object.assign({}, state, {
                addQrTablesLoading: true
            })
        case ADD_QR_TABLES_ENDED:
            return Object.assign({}, state, {
                addQrTablesLoading: false
            })
        case ADD_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                addQrButtonLoading: true
            })
        case ADD_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                addQrButtonLoading: false
            })
        case EDIT_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                editQrButtonLoading: true
            })
        case EDIT_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                editQrButtonLoading: false
            })
        case DELETE_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                deleteQrButtonLoading: true
            })
        case DELETE_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                deleteQrButtonLoading: false
            })
        case DELETE_QR_TABLE_STARTED:
            return Object.assign({}, state, {
                deleteQrTablesLoading: true
            })
        case DELETE_QR_TABLE_ENDED:
            return Object.assign({}, state, {
                deleteQrTablesLoading: false
            })
        case GET_QR_BUTTON_REQUEST:
            return Object.assign({}, state, {
                getQrButtonLoading: true
            })
        case GET_QR_BUTTON_SUCCESS:
            return Object.assign({}, state, {
                getQrButtonLoading: false,
                button: action.payload
            })
        case GET_QR_BUTTON_ERROR:
            return Object.assign({}, state, {
                getQrButttonLoading: false,
                button: {}
            })
        case GET_QR_BUTTONS_REQUEST:
            return Object.assign({}, state, {
                getQrButtonsLoading: true,
                selectedRestaurantButtons: action.payload.ruuid,
                selectedBranchButtons: action.payload.bruuid
            })
        case GET_QR_BUTTONS_SUCCESS:
            return Object.assign({}, state, {
                getQrButtonsLoading: false,
                buttons: action.payload
            })
        case GET_QR_BUTTONS_ERROR:
            return Object.assign({}, state, {
                getQrButtonsLoading: false,
                buttons: []
            })
        case GET_QR_REQUEST:
            return Object.assign({}, state, {
                getQrLoading: true,
                selectedRestaurantQR: action.payload.ruuid,
                selectedBranchQR: action.payload.bruuid
            })
        case GET_QR_SUCCESS:
            return Object.assign({}, state, {
                getQrLoading: false,
                qr: action.payload
            })
        case GET_QR_ERROR:
            return Object.assign({}, state, {
                getQrLoading: false,
                qr: null
            })
        case GET_QR_TABLES_REQUEST:
            return Object.assign({}, state, {
                getQrTablesLoading: true,
                selectedBranchTables: action.payload.bruuid,
                selectedRestaurantTables: action.payload.ruuid
            })
        case GET_QR_TABLES_SUCCESS:
            return Object.assign({}, state, {
                getQrTablesLoading: false,
                tables: action.payload
            })
        case GET_QR_TABLES_ERROR:
            return Object.assign({}, state, {
                getQrTablesLoading: false,
                tables: []
            })
        case VALIDATE_URL_STARTED:
            return Object.assign({}, state, {
                validateLoading: true
            })
        case VALIDATE_URL_ENDED:
            return Object.assign({}, state, {
                validateLoading: false
            })
        case VALIDATE_URL_SUCCESS:
            return Object.assign({}, state, {
                validatedUrl: action.payload
            })
        //entity qr cases
        case GET_ENTITY_QR_REQUEST:
            return Object.assign({}, state, {
                getEntityQrLoading: true,
                selectedEntityQr: action.payload.euuid
            })
        case GET_ENTITY_QR_SUCCESS:
            return Object.assign({}, state, {
                getEntityQrLoading: false,
                entityQr: action.payload
            })
        case GET_ENTITY_QR_ERROR:
            return Object.assign({}, state, {
                getEntityQrLoading: false,
                entityQr: null
            })
        case ADD_EDIT_ENTITY_QR_STARTED:
            return Object.assign({}, state, {
                addEditEntityQrLoading: true
            })
        case ADD_EDIT_ENTITY_QR_ENDED:
            return Object.assign({}, state, {
                addEditEntityQrLoading: false
            })
        //entity qr tables cases
        case GET_ENTITY_QR_TABLES_REQUEST:
            return Object.assign({}, state, {
                getEntityQrTablesLoading: true,
                selectedEntityTables: action.payload.euuid
            })
        case GET_ENTITY_QR_TABLES_SUCCESS:
            return Object.assign({}, state, {
                getEntityQrTablesLoading: false,
                entityTables: action.payload
            })
        case GET_ENTITY_QR_TABLES_ERROR:
            return Object.assign({}, state, {
                getEntityQrTablesLoading: false,
                entityTables: []
            })
        case ADD_ENTITY_QR_TABLES_STARTED:
            return Object.assign({}, state, {
                addEntityQrTablesLoading: true
            })
        case ADD_ENTITY_QR_TABLES_ENDED:
            return Object.assign({}, state, {
                addEntityQrTablesLoading: false
            })
        case DELETE_ENTITY_QR_TABLE_STARTED:
            return Object.assign({}, state, {
                deleteEntityQrTablesLoading: true
            })
        case DELETE_ENTITY_QR_TABLE_ENDED:
            return Object.assign({}, state, {
                deleteEntityQrTablesLoading: false
            })
        //entity qr buttons cases
        case GET_ENTITY_QR_BUTTON_REQUEST:
            return Object.assign({}, state, {
                getEntityQrButtonLoading: true
            })
        case GET_ENTITY_QR_BUTTON_SUCCESS:
            return Object.assign({}, state, {
                getEntityQrButtonLoading: false,
                entityButton: action.payload
            })
        case GET_ENTITY_QR_BUTTON_ERROR:
            return Object.assign({}, state, {
                getEntityQrButttonLoading: false,
                entityButton: {}
            })
        case GET_ENTITY_QR_BUTTONS_REQUEST:
            return Object.assign({}, state, {
                getEntityQrButtonsLoading: true,
                selectedEntityButtons: action.payload.euuid
            })
        case GET_ENTITY_QR_BUTTONS_SUCCESS:
            return Object.assign({}, state, {
                getEntityQrButtonsLoading: false,
                entityButtons: action.payload
            })
        case GET_ENTITY_QR_BUTTONS_ERROR:
            return Object.assign({}, state, {
                getEntityQrButtonsLoading: false,
                entityButtons: []
            })
        case ADD_ENTITY_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                addEntityQrButtonLoading: true
            })
        case ADD_ENTITY_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                addEntityQrButtonLoading: false
            })
        case EDIT_ENTITY_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                editEntityQrButtonLoading: true
            })
        case EDIT_ENTITY_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                editEntityQrButtonLoading: false
            })
        case DELETE_ENTITY_QR_BUTTON_STARTED:
            return Object.assign({}, state, {
                deleteEntityQrButtonLoading: true
            })
        case DELETE_ENTITY_QR_BUTTON_ENDED:
            return Object.assign({}, state, {
                deleteEntityQrButtonLoading: false
            })
        default:
            return state
    }
}
export default qr