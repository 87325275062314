import { NAVIGATE_TO } from '../actions/NavigationActions';

const initialState = {
  path: null,
};

function navigationReducer (state = initialState, action) {
  switch (action.type) {
    case NAVIGATE_TO:
      return { ...state, path: action.payload };
    default:
      return state;
  }
};
export default navigationReducer
