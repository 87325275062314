import { getEntities, addEntity, editEntity, deleteEntity } from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_ENTITIES_REQUEST = 'GET_ENTITIES_REQUEST'
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS'
export const GET_ENTITIES_ERROR = 'GET_ENTITIES_ERROR'

export const ADD_ENTITY_STARTED = 'ADD_ENTITY_STARTED'
export const ADD_ENTITY_END = 'ADD_ENTITY_END'

export const EDIT_ENTITY_STARTED = 'EDIT_ENTITY_STARTED'
export const EDIT_ENTITY_END = 'EDIT_ENTITY_END'

export const DELETE_ENTITY_STARTED = 'DELETE_ENTITY_STARTED'
export const DELETE_ENTITY_END = 'DELETE_ENTITY_END'

export const SET_ENTITY_POPUP = 'SET_ENTITY_POPUP'

export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY'
export const SET_IS_ENTITY_SELECTION = 'SET_IS_ENTITY_SELECTION'

export const CLEAR_ALL_ENTITIES = 'CLEAR_ALL_ENTITIES'

const actions = {
  getEntities: () => async (dispatch) => {
    dispatch({
        type: GET_ENTITIES_REQUEST
    });
    await getEntities().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ENTITIES_SUCCESS,
          payload: {
            entities: res?.data?.entities,
          },
        })
      } else {
        handleError(dispatch)(res, GET_ENTITIES_ERROR, 'GET ENTITIES ERROR')
      }
    })
  },
  addEntity: (data) => async (dispatch) => {
    dispatch({
      type: ADD_ENTITY_STARTED,
    })
    await addEntity(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_ENTITIES_SUCCESS,
          payload: {
            entities: res?.data?.entities,
          },
        })
        dispatch({
          type: SET_ENTITY_POPUP,
          payload: false,
        })
        // history.goBack()
      } else {
        handleError(dispatch)(res, null, 'ADD ENTITY ERROR')
      }
    })
    dispatch({
      type: ADD_ENTITY_END,
    })
  },

  editEntity: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_ENTITY_STARTED,
    })
    await editEntity(uuid, data).then((res) => {
      console.log(res)
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_ENTITIES_SUCCESS,
          payload: {
            entities: res?.data?.entities,
          },
        })
        dispatch({
          type: SET_ENTITY_POPUP,
          payload: false,
        })
      setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT ENTITY ERROR')
      }
    })
    dispatch({
      type: EDIT_ENTITY_END,
    })
  },

  deleteEntity: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_ENTITY_STARTED,
    })
    await deleteEntity(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_ENTITIES_SUCCESS,
          payload: {
            entities: res?.data?.entities,
          },
        })
       dispatch({
         type: SET_ENTITY_POPUP,
         payload: false,
      })
      } else {
        handleError(dispatch)(res, null, 'DELETE ENTITY ERROR')
      }
    })
    dispatch({
      type: DELETE_ENTITY_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_ENTITY_POPUP,
      payload: state,
    })
  },

  setSelectedEntity: (entity) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_ENTITY,
      payload: entity
    })
  },
  
  setIsEntitySelection: (value) => (dispatch) => {
    dispatch({
      type: SET_IS_ENTITY_SELECTION,
      payload: value
    })
  },

  // CLEAR ALL Reducer
  clearAllEntities: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_ENTITIES,
    })
  },
}

export default actions
