import {
  GET_MODIFIER_REQUEST,
  GET_MODIFIER_SUCCESS,
  GET_MODIFIER_ERROR,
  CLEAR_ALL_MODIFIER,
  ADD_MODIFIER_STARTED,
  ADD_MODIFIER_END,
  EDIT_MODIFIER_STARTED,
  EDIT_MODIFIER_END,
  DELETE_MODIFIER_STARTED,
  DELETE_MODIFIER_END,
  SET_MODIFIER_POPUP,
  CLEAR_MODIFIERS_LOADING,
  BULK_UPDATE_MODIFIERS_STARTED,
  BULK_UPDATE_MODIFIERS_ENDED,
  UPDATE_MODIFIER_STARTED,
  UPDATE_MODIFIER_END,
} from '../actions/ModifiersActions'

const initialState = {
  loading: false,
  bulkLoading: false,
  modifiers: [],
  modifierPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedRestaurant: '',
  updateModifiersLoading: false,
}

function modifier(state = initialState, action) {
  switch (action.type) {
    case BULK_UPDATE_MODIFIERS_ENDED:
      return Object.assign({}, state, {
        bulkLoading: false
      })
    case BULK_UPDATE_MODIFIERS_STARTED:
      return Object.assign({}, state, {
        bulkLoading: true
      })
    case CLEAR_MODIFIERS_LOADING:
      Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_MODIFIER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedrestaurant: action.payload
      })
    case GET_MODIFIER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        modifiers: action.payload.modifiers,
      })
    case GET_MODIFIER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        modifiers: [],
      })

    case ADD_MODIFIER_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_MODIFIER_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_MODIFIER_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_MODIFIER_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

      case UPDATE_MODIFIER_STARTED:
        return Object.assign({}, state, {
          updateModifiersLoading: true,
        })
  
      case UPDATE_MODIFIER_END:
        return Object.assign({}, state, {
          updateModifiersLoading: false,
        })

    case DELETE_MODIFIER_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_MODIFIER_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_MODIFIER_POPUP:
      return Object.assign({}, state, {
        modifierPopup: action.payload,
      })

    case CLEAR_ALL_MODIFIER:
      return initialState

    default:
      return state
  }
}

export default modifier
