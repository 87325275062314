import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/system'

const components = {
    promotions: lazy(() => import('../../pages/offers/PromoCode')),
    discounts: lazy(() => import('../../pages/offers/Discount')),
    invoiceDiscount: lazy(() => import('../../pages/offers/InvoiceDiscounts')),
    addEditDiscount: lazy(() => import('../../pages/offers/AddEditDiscount')),
    addPromo: lazy(() => import('../../pages/offers/AddEditPromoCode')),
    editPromo: lazy(() => import('../../pages/offers/AddEditPromoCode')),
    addDiscount: lazy(() => import('../../pages/offers/AddEditDiscount')),
    editDiscount: lazy(() => import('../../pages/offers/AddEditDiscount')),
    add: lazy(() => import('../../pages/offers/AddEditInvoiceDiscount')),
    edit: lazy(() => import('../../pages/offers/AddEditInvoiceDiscount')),
};

function OffersLayout({
    offersActiveTab,
    setPathCheck,
    permission,
    reformat
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });
    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    return (
        <div>
            <Box>
                <Switch>
                    <Route exact path={permission.route} render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    {/* <UserRoute exact path="/crm/offers/promo-code" component={PromoCode} />
                    <UserRoute exact path="/crm/offers/promo-code/add-edit" component={AddEditPromoCode} />
                    <UserRoute exact path="/crm/offers/discount/add-edit" component={AddEditDiscount} />
                    <UserRoute exact path="/crm/offers/discount" component={Discount} /> */}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    offersActiveTab: state.layout.offersActiveTab
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OffersLayout))