import { getStripeAccount, createStripeAccount, createStripeSession} from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_STRIPE_ACCOUNT_REQUEST = 'GET_STRIPE_ACCOUNT_REQUEST'
export const GET_STRIPE_ACCOUNT_SUCCESS = 'GET_STRIPE_ACCOUNT_SUCCESS'
export const GET_STRIPE_ACCOUNT_ERROR = 'GET_STRIPE_ACCOUNT_ERROR'

export const ADD_STRIPE_ACCOUNT_STARTED = 'ADD_STRIPE_ACCOUNT_STARTED'
export const ADD_STRIPE_ACCOUNT_END = 'ADD_STRIPE_ACCOUNT_END'

// export const EDIT_STRIPE_ACCOUNT_STARTED = 'EDIT_STRIPE_ACCOUNT_STARTED'
// export const EDIT_STRIPE_ACCOUNT_END = 'EDIT_STRIPE_ACCOUNT_END'

// export const DELETE_STRIPE_ACCOUNT_STARTED = 'DELETE_STRIPE_ACCOUNT_STARTED'
// export const DELETE_STRIPE_ACCOUNT_END = 'DELETE_STRIPE_ACCOUNT_END'

// export const SET_STRIPE_ACCOUNT_POPUP = 'SET_STRIPE_ACCOUNT_POPUP'
// export const CLEAR_ALL_STRIPE_ACCOUNT = 'CLEAR_ALL_STRIPE_ACCOUNT'

const actions = {
    getStripeAccount: (ruuid , buuid) => async (dispatch) => {
    dispatch({
      type: GET_STRIPE_ACCOUNT_REQUEST,
    })
    await getStripeAccount(ruuid , buuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_STRIPE_ACCOUNT_SUCCESS,
          payload: {
            ...res?.data
          },
        })
      } else {
        handleError(dispatch)(res, GET_STRIPE_ACCOUNT_ERROR, 'GET STRIPE ERROR')
      }
    })
  },

  createAccount: (ruuid , buuid , postSuccessAction) => async (dispatch) => {
    dispatch({
      type: ADD_STRIPE_ACCOUNT_STARTED,
    })
    await createStripeAccount(ruuid , buuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_STRIPE_ACCOUNT_SUCCESS,
          payload: {
            ...res?.data
          },
        })

        postSuccessAction()
      } else {
        handleError(dispatch)(res, ADD_STRIPE_ACCOUNT_END, 'GET STRIPE ERROR')
      }
    })
  }, 

//   addRole: (data) => async (dispatch) => {
//     dispatch({
//       type: ADD_STRIPE_ACCOUNT_STARTED,
//     })
//     await createStripeAccount().then(async (res) => {
//       if (res?.status === 200) {
//         await getRoles().then((res) => {
//           if (res?.status === 200) {
//             dispatch({
//               type: GET_STRIPE_ACCOUNT_SUCCESS,
//               payload: {
//                 roles: res?.data?.roles,
//                 allPages: res?.data?.allPages
//               },
//             })
//           } else {
//             handleError(dispatch)(res, GET_STRIPE_ACCOUNT_ERROR, 'GET STRIPE ERROR')
//           }
//         })
//         toast.success(res?.data?.message || 'ADD STRIPE SUCCESS')
//       } else {
//         handleError(dispatch)(res, null, 'ADD STRIPE ERROR')
//       }
//     })
//     dispatch({
//       type: ADD_STRIPE_ACCOUNT_END,
//     })
//   }
}

export default actions
