import {
  GET_DELIVERY_SOURCES_REQUEST,
  GET_DELIVERY_SOURCES_SUCCESS,
  GET_DELIVERY_SOURCES_ERROR,
  ADD_DELIVERY_SOURCES_STARTED,
  ADD_DELIVERY_SOURCES_END,
  EDIT_DELIVERY_SOURCES_STARTED,
  EDIT_DELIVERY_SOURCES_END,
  DELETE_DELIVERY_SOURCES_STARTED,
  DELETE_DELIVERY_SOURCES_END,
  SET_DELIVERY_SOURCES_POPUP,
  CLEAR_ALL_DELIVERY_SOURCES,
  BULK_UPDATE_DELIVERY_SOURCES_STARTED,
  BULK_UPDATE_DELIVERY_SOURCES_ENDED,
  CLEAR_TYPES_LOADING,
  UPDATE_DELIVERY_SOURCES_STARTED,
  UPDATE_DELIVERY_SOURCES_END,
} from '../actions/DeliverySourcesActions'


const initialState = {
  loading: false,
  deliverySources: [],
  deliverySourcesPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedRestaurant: '',
  updatedeliverySourcesLoading: false,
}

function deliverySources(state = initialState, action) {
  switch (action.type) {
    
    case BULK_UPDATE_DELIVERY_SOURCES_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case BULK_UPDATE_DELIVERY_SOURCES_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case GET_DELIVERY_SOURCES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_DELIVERY_SOURCES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        deliverySources: action.payload.deliverySources,
      })
    case GET_DELIVERY_SOURCES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        deliverySources: [],
      })

    case ADD_DELIVERY_SOURCES_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_DELIVERY_SOURCES_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_DELIVERY_SOURCES_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_DELIVERY_SOURCES_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

      case UPDATE_DELIVERY_SOURCES_STARTED:
        return Object.assign({}, state, {
          updatedeliverySourcesLoading: true,
        })
  
      case UPDATE_DELIVERY_SOURCES_END:
        return Object.assign({}, state, {
          updatedeliverySourcesLoading: false,
        })

    case DELETE_DELIVERY_SOURCES_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_DELIVERY_SOURCES_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_DELIVERY_SOURCES_POPUP:
      return Object.assign({}, state, {
        deliverySourcesPopup: action.payload,
      })

    case CLEAR_ALL_DELIVERY_SOURCES:
      return initialState

    default:
      return state
  }
}

export default deliverySources
