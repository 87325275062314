import {
  GET_TYPES_AND_ALLERGIES_REQUEST,
  GET_TYPES_AND_ALLERGIES_SUCCESS,
  GET_TYPES_AND_ALLERGIES_ERROR,
  ADD_TYPES_AND_ALLERGIES_STARTED,
  ADD_TYPES_AND_ALLERGIES_END,
  EDIT_TYPES_AND_ALLERGIES_STARTED,
  EDIT_TYPES_AND_ALLERGIES_END,
  DELETE_TYPES_AND_ALLERGIES_STARTED,
  DELETE_TYPES_AND_ALLERGIES_END,
  SET_TYPES_AND_ALLERGIES_POPUP,
  CLEAR_ALL_TYPES_AND_ALLERGIES,
  BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED,
  BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED,
  CLEAR_TYPES_LOADING,
  UPDATE_TYPES_AND_ALLERGIES_STARTED,
  UPDATE_TYPES_AND_ALLERGIES_END,
} from '../actions/TypesAndAllergiesActions'


const initialState = {
  loading: false,
  typesAndAllergies: [],
  typesAndAllergiesPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedRestaurant: '',
  updateTypesAndAllergiesLoading: false,
}

function typesAndAllergies(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TYPES_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case GET_TYPES_AND_ALLERGIES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_TYPES_AND_ALLERGIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        typesAndAllergies: action.payload.typesAndAllergies,
      })
    case GET_TYPES_AND_ALLERGIES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        typesAndAllergies: [],
      })

    case ADD_TYPES_AND_ALLERGIES_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_TYPES_AND_ALLERGIES_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_TYPES_AND_ALLERGIES_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_TYPES_AND_ALLERGIES_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

      case UPDATE_TYPES_AND_ALLERGIES_STARTED:
        return Object.assign({}, state, {
          updateTypesAndAllergiesLoading: true,
        })
  
      case UPDATE_TYPES_AND_ALLERGIES_END:
        return Object.assign({}, state, {
          updateTypesAndAllergiesLoading: false,
        })

    case DELETE_TYPES_AND_ALLERGIES_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_TYPES_AND_ALLERGIES_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_TYPES_AND_ALLERGIES_POPUP:
      return Object.assign({}, state, {
        typesAndAllergiesPopup: action.payload,
      })

    case CLEAR_ALL_TYPES_AND_ALLERGIES:
      return initialState

    default:
      return state
  }
}

export default typesAndAllergies
