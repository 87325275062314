import {
  GET_DELIVERYZONES_REQUEST,
  GET_DELIVERYZONES_SUCCESS,
  GET_DELIVERYZONES_ERROR,
  CLEAR_ALL_DELIVERYZONE,
  ADD_DELIVERYZONE_STARTED,
  ADD_DELIVERYZONE_END,
  EDIT_DELIVERYZONE_STARTED,
  EDIT_DELIVERYZONE_END,
  DELETE_DELIVERYZONE_STARTED,
  DELETE_DELIVERYZONE_END,
  SET_SELECTED_DELIVERYZONE,
  ACTION_STARTED,
  ACTION_ENDED,
  SET_DELIVERY_SETTINGS_MODAL_STATE,
  GET_DELIVERYZONES_SETTINGS_REQUEST,
  GET_DELIVERYZONES_SETTINGS_SUCCESS,
  GET_DELIVERYZONES_SETTINGS_ERROR,
  ADD_EDIT_DELIVERYZONE_SETTINGS_STARTED,
  ADD_EDIT_DELIVERYZONE_SETTINGS_END,
  CLEAR_ZONES_LOADING,
  GET_DELIVERYZONES_SETTINGS_ALL_REQUEST,
  GET_DELIVERYZONES_SETTINGS_ALL_SUCCESS,
  GET_DELIVERYZONES_SETTINGS_ALL_ERROR
} from "../actions/DeliveryZoneActions";

const initialState = {
  loading: false,
  deliveryZones: [],
  selectedDeliveryZone: "",
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  globalSettings: [],
  deliverySettingsModalState: false,
  selectedRestaurant: '',
  selectedBranch: '',
  selectedBranchSettings: '',
  deliverySettingsLoading: false,
  deliverySettingsActionLoading: false,
  deliverySettings: null,
  deliverySettingsAll: [],
  deliverySettingsAllLoading: false
};

function zone(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ZONES_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        deliverySettingsLoading: false,
        deliverySettingsActionLoading: false,
      })
    case ADD_EDIT_DELIVERYZONE_SETTINGS_STARTED:
      return Object.assign({}, state, {
        deliverySettingsActionLoading: true,
      });

    case ADD_EDIT_DELIVERYZONE_SETTINGS_END:
      return Object.assign({}, state, {
        deliverySettingsActionLoading: false,
      });
    case GET_DELIVERYZONES_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        deliverySettingsLoading: true,
        selectedBranchSettings: action.payload
      });
    case GET_DELIVERYZONES_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        deliverySettingsLoading: false,
        deliverySettings: action.payload,
      });
    case GET_DELIVERYZONES_SETTINGS_ERROR:
      return Object.assign({}, state, {
        deliverySettingsLoading: false,
        deliverySettings: null,
      });

    case GET_DELIVERYZONES_SETTINGS_ALL_REQUEST:
      return Object.assign({}, state, {
        deliverySettingsAllLoading: true
      });
    case GET_DELIVERYZONES_SETTINGS_ALL_SUCCESS:
      return Object.assign({}, state, {
        deliverySettingsAllLoading: false,
        deliverySettingsAll: action.payload,
      });
    case GET_DELIVERYZONES_SETTINGS_ALL_ERROR:
      return Object.assign({}, state, {
        deliverySettingsAllLoading: false,
        deliverySettingsAll: [],
      });

    case GET_DELIVERYZONES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload.ruuid,
        selectedBranch: action.payload.bruuid
      });
    case GET_DELIVERYZONES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        deliveryZones: action.payload.deliveryZones,
        globalSettings: action.payload.globalSettings,
      });
    case GET_DELIVERYZONES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        deliveryZones: [],
      });

    case ADD_DELIVERYZONE_STARTED:
      return Object.assign({}, state, {
        loading: true,
      });

    case ADD_DELIVERYZONE_END:
      return Object.assign({}, state, {
        loading: false,
      });

    case EDIT_DELIVERYZONE_STARTED:
      return Object.assign({}, state, {
        loading: true,
      });

    case EDIT_DELIVERYZONE_END:
      return Object.assign({}, state, {
        loading: false,
      });

    case DELETE_DELIVERYZONE_STARTED:
      return Object.assign({}, state, {
        loading: true,
      });

    case DELETE_DELIVERYZONE_END:
      return Object.assign({}, state, {
        loading: false,
      });

    case SET_SELECTED_DELIVERYZONE:
      return Object.assign({}, state, {
        selectedDeliveryZone: action.data,
      });

    case CLEAR_ALL_DELIVERYZONE:
      return Object.assign({}, state, {
        loading: false,
        deliveryZones: [],
        selectedDeliveryZone: "",
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        globalSettings: [],
        selectedRestaurant: '',
        selectedBranch: '',
        selectedBranchSettings: '',
        deliverySettingsLoading: false,
        deliverySettingsActionLoading: false,
        //  deliverySettings: null
      })
    case ACTION_STARTED:
      return Object.assign({}, state, {
        loading: true
      })
    case ACTION_ENDED:
      return Object.assign({}, state, {
        loading: false
      })
    case SET_DELIVERY_SETTINGS_MODAL_STATE:
      return Object.assign({}, state, {
        deliverySettingsModalState: action.payload
      })
    default:
      return state;
  }
}

export default zone;
