import {
    getRestaurantsDesignSetup,
    editRestaurantsDesignSetup
} from "../../config/config";
import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_RESTAURANTS_DESIGN_SETUP_REQUEST = "GET_RESTAURANTS_DESIGN_SETUP_REQUEST";
export const GET_RESTAURANTS_DESIGN_SETUP_SUCCESS = "GET_RESTAURANTS_DESIGN_SETUP_SUCCESS";
export const GET_RESTAURANTS_DESIGN_SETUP_ERROR = "GET_RESTAURANTS_DESIGN_SETUP_ERROR";
export const EDIT_RESTAURANTS_DESIGN_SETUP_STARTED = "EDIT_RESTAURANTS_DESIGN_SETUP_STARTED";
export const EDIT_RESTAURANTS_DESIGN_SETUP_ENDED = "EDIT_RESTAURANTS_DESIGN_SETUP_ENDED";

const actions = {
    getRestaurantsDesignSetup: () => async (dispatch) => {
            dispatch({
                type: GET_RESTAURANTS_DESIGN_SETUP_REQUEST
            });
        await getRestaurantsDesignSetup().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_RESTAURANTS_DESIGN_SETUP_SUCCESS,
                    payload: {
                        restaurantsDesignSetup: res?.data?.restaurantsDesignSetup,
                        mainDesignSetup: res?.data?.mainDesignSetup
                    },
                });
            } else {
                handleError(dispatch)(res, GET_RESTAURANTS_DESIGN_SETUP_ERROR, 'Get restaurants design setup error')
            }
        });
    },

    editRestaurantsDesignSetup: (data) => async (dispatch) => {
        dispatch({
            type: EDIT_RESTAURANTS_DESIGN_SETUP_STARTED,
        });

        await editRestaurantsDesignSetup(data).then(async (res) => {
            if (res?.status === 200) {
                toast.success(res.data.message || 'Successfully updated')
                dispatch({
                    type: EDIT_RESTAURANTS_DESIGN_SETUP_ENDED,
                    payload: {
                        restaurantsDesignSetup: res?.data?.restaurantsDesignSetup,
                    },
                });

                await dispatch(actions?.getRestaurantsDesignSetup());
            } else {
                dispatch({
                    type: EDIT_RESTAURANTS_DESIGN_SETUP_ENDED,
                });
                handleError(dispatch)(res, null, 'Edit restaurants design setup error')
            }
        });
    },
};

export default actions;
