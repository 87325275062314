import moment, { defaultFormatUtc } from "moment";
import * as yup from "yup";
import { toast } from "react-toastify";
import { LOGOUT_USER, SET_PERMISSIONS } from "../redux/actions/AuthActions";
import { getSupportedLanguages } from "./languageService";
import { Box } from "@mui/material";
import { navigateTo } from "../redux/actions/NavigationActions";

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return 0;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return;
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getPagePermission(allPages, pageKey) {
  let final
  allPages.map((page) => {
    if (page.key === pageKey)
      return final = page
    if (!final && page.subPage?.length > 0)
      final = getPagePermission(page.subPage, pageKey)
  })
  return final
}

export function getPagePermissionByPath(allPages, path) {
  function pathToRegex(route) {
    // Replace :param with a regex that matches any non-slash characters
    return new RegExp('^' + route.replace(/:[^\s/]+/g, '([^\\/]+)') + '$');
  }

  let final;
  allPages.some((page) => {
    if (typeof page.route === 'string' && pathToRegex(page.route).test(path)) {
      final = page;
      return true; // Exit loop when page is found
    }
    if (!final && page.subPage?.length > 0) {
      final = getPagePermissionByPath(page.subPage, path); // Recursive call
      return !!final; // Exit loop if final is found in subPage
    }
    return false; // Continue iterating
  });
  return final;
}



export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  var definitions = search.split("&");
  definitions.forEach(function (val) {
    var parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(" ");
}

export function initCodeViewer() {
  if (!document) return;
  const pre = document.getElementsByTagName("pre");
  if (!pre.length) return;
  Array.prototype.map.call(pre, (p) => {
    p.classList.add("collapsed");
    p.addEventListener("click", (e) => {
      e.target.classList.remove("collapsed");
    });
  });
}

export const reformatArray = (arr, formats, language) => {
  let final = [];
  if (arr?.length > 0 && formats?.length > 0) {
    arr.map((zone) => {
      let obj = {};
      formats?.map((format) => {
        let temp = format[1].split(".");
        let field;
        if (temp.length > 1) {
          for (let i = 0; i < temp.length; i++) {
            if (i === 0) field = zone[temp[i]];
            else field = field[temp[i]];
          }
        } else field = zone[format[1]];
        if (language) {
          obj[format[0]] = field[language] ? field[language] : field['english'] ? field['english'] : field;
        } else obj[format[0]] = field;
        return 0;
      });
      final.push(obj);
      return 0;
    });
  }
  return final;
};

export const getTranslation = (pageName, data) => {
  let final = {};
  data[0]?.languages?.map((zone) => {
    if (zone.page === pageName) {
      final = zone.values || {};
    }
    return 0;
  })
  return final;
};

export const getField = (dictionary, fieldName, language) => {
  if (!dictionary) return "No translation";
  if (!dictionary[fieldName]) return 'No translation'
  if (!dictionary[fieldName][language] && !dictionary[fieldName]['english']) return "No translation";
  return (
    dictionary[fieldName][language] || dictionary[fieldName]['english'] || ""
  );
};

export const getFieldData = (data, fieldName, language) => {
  const supportedLanguages = getSupportedLanguages();
  const defaultLanguage = supportedLanguages.find((lang) => lang.isDefault)?.name;

  let temp = fieldName?.split('.');
  let field;

  temp?.map((tmp, i) => {
    if (i === 0) field = data?.[tmp];
    else field = field?.[tmp];
  });

  if (!fieldName) field = data;
  if (!data) return "No translation";
  if (!field) return "No translation";
  if (!field[language] && !field[defaultLanguage] && !field[['english']]) return "No translation";
  return field[language] || field[defaultLanguage] || field['english'] || "";
};

export const generateMultipleLangsValidation = (
  supportedLanguages,
  translation
) => {
  let final = {};
  supportedLanguages?.map((language, i) => {
    final[language.name] =
      language.isDefault
        ? yup.string().required(translation || "")
        : yup.string().optional();
    return 0;
  });
  return yup.object().shape(final);
};

export const getInitialState = (supportedLanguages, type, data, fallBack, def) => {
  let final = {};
  let defaultLanguage = supportedLanguages.find((lang) => lang.isDefault)
  supportedLanguages?.map((language) => {
    final[language.name] =
      type === 0
        ? language.name === defaultLanguage.name && def ? def : ""
        : data
          ? data[language.name]
            ? data[language.name]
            : ""
          : fallBack
            ? fallBack[language.name] || ""
            : "";
    return 0;
  });
  return final;
};

export const getInitialStateArray = (supportedLanguages, keys, data) => {
  let final = []
  data.map((row) => {
    let obj = { ...row }
    keys.map((key) => {
      obj[key] = getInitialState(supportedLanguages, 1, obj[key])
    })
    final.push(obj)
  })
  return final
}

export const generateFields = (supportedLanguages, field, fieldProps) => {
  return supportedLanguages?.map((language, i) => {
    return (
      <Box sx={{ display: 'flex' }}>
        {field(fieldProps(language.name), i, language)}
        <div id={`portal-target-${language.name}`}></div>
      </Box>
    );
  });
};

export const getFieldName = (supportedLanguages, fieldKey) => {
  const defaultLanguage = supportedLanguages.find((lang) => lang.isDefault)
  if (defaultLanguage)
    return fieldKey + `.${defaultLanguage.name}`
}

export const getFieldValue = (supportedLanguages, field) => {
  const defaultLanguage = supportedLanguages.find((lang) => lang.isDefault)
  if (defaultLanguage && field)
    return field[defaultLanguage.name]
}

export const setFieldValue = (supportedLanguages, setFieldValue, fieldKey, newValue) => {
  const defaultLanguage = supportedLanguages.find((lang) => lang.isDefault)
  setFieldValue(`${fieldKey}.${defaultLanguage.name}`, newValue)
}

export const removeEmptyLang = (supportedLanguages, field) => {
  let final = field;
  supportedLanguages?.map((language) => {
    if (final[language.name] === "") delete final[language.name];
    return 0;
  });
  return final;
};

export const getTitleCase = (str) => {
  if (str) {
    const titleCase = str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }
  return " ";
};

export const getParagraphCase = (inputStr) => {
  if (inputStr) {
    let words = inputStr.toLowerCase().split(" ");
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    for (let i = 1; i < words.length; i++) {
      if (/[\.\?!]\s*/.test(words[i - 1])) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      } else {
        words[i] = words[i].toLowerCase();
      }
    }
    return words.join(" ");
  }
  return "";
};

export const hexToRgb = (hexColor) => {
  let r, g, b;
  if (hexColor?.charAt(0) === "#") {
    hexColor = hexColor?.substr(1);
    r = parseInt(hexColor?.substr(0, 2), 16);
    g = parseInt(hexColor?.substr(2, 2), 16);
    b = parseInt(hexColor?.substr(4, 2), 16);
  } else {
    // If it's not in hex format, handle other formats (e.g., rgb, rgba)
    const matchColors = hexColor?.match(/\d+/g);
    if (matchColors.length === 3) {
      [r, g, b] = matchColors.map((color) => parseInt(color, 10));
    } else if (matchColors.length === 4) {
      [r, g, b] = matchColors.slice(0, 3).map((color) => parseInt(color, 10));
    } else {
      return null; // Unable to determine color
    }
  }

  const rgbValue = `rgb(${r}, ${g}, ${b})`;

  return {
    r,
    g,
    b,
    value: rgbValue
  };
}


export const getContrastColor = (backgroundColor, lightBgContrastColor = "#000000", darkBgContrastColor = "#FFFFFF", maxDarkBgLuminance = 0.5) => {
  if (!backgroundColor) {
    return null;
  }
  // Convert hex color to RGB
  let r, g, b;
  if (backgroundColor.charAt(0) === "#") {
    backgroundColor = backgroundColor.substr(1);
    r = parseInt(backgroundColor.substr(0, 2), 16);
    g = parseInt(backgroundColor.substr(2, 2), 16);
    b = parseInt(backgroundColor.substr(4, 2), 16);
  } else {
    // If it's not in hex format, handle other formats (e.g., rgb, rgba)
    const matchColors = backgroundColor.match(/\d+/g);
    if (matchColors.length === 3) {
      [r, g, b] = matchColors.map((color) => parseInt(color, 10));
    } else if (matchColors.length === 4) {
      [r, g, b] = matchColors.slice(0, 3).map((color) => parseInt(color, 10));
    } else {
      return null; // Unable to determine color
    }
  }

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return contrast color based on luminance
  return luminance > maxDarkBgLuminance ? lightBgContrastColor : darkBgContrastColor;
};

// function getContrastColor(hexColor) {
//     // Remove the hash character if it's included
//     hexColor = hexColor?.replace("#", "");

//     // Convert hex color to RGB
//     const r = parseInt(hexColor?.substring(0, 2), 16);
//     const g = parseInt(hexColor?.substring(2, 4), 16);
//     const b = parseInt(hexColor?.substring(4, 6), 16);

//     // Calculate relative luminance (brightness) using the WCAG formula
//     const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

//     // Use white font color for dark backgrounds, and black for light backgrounds
//     return brightness > 0.5 ? "#000000" : "#ffffff";
// }

export const handleError = (dispatch) => (response, type, defaultMessage) => {
  if (response.status !== 401 && response.status !== 403)
    toast.error(response?.data?.message || defaultMessage);
  if (response.status === 401) {
    dispatch({ type: LOGOUT_USER });
  }
  if (response.status === 403) {
    dispatch(navigateTo('/forbidden'))
    dispatch({
      type: SET_PERMISSIONS,
      payload: response?.data?.permissions
    })
  }
  if (type)
    dispatch({
      type: type,
    });
}

export function deepEqual(obj1, obj2, excludeKeys) {
  // Check if both inputs are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    // Sort the arrays to ensure the elements are in the same order for comparison
    obj1.sort();
    obj2.sort();

    // Compare each element of the arrays recursively
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i], excludeKeys)) {
        return false;
      }
    }

    return true;
  }

  // Check if both inputs are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object' && obj1 !== null && obj2 !== null) {
    const keys1 = Object.keys(obj1).filter(key => !excludeKeys?.includes(key)).sort();
    const keys2 = Object.keys(obj2).filter(key => !excludeKeys?.includes(key)).sort();

    if (keys1.length !== keys2.length || !deepEqual(keys1, keys2)) {
      return false;
    }

    for (const key of keys1) {
      if (!deepEqual(obj1[key], obj2[key], excludeKeys)) {
        return false;
      }
    }

    return true;
  }

  // If not arrays or objects, compare directly
  return obj1 === obj2;
}