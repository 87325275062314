import {
    getDeliverySources,
    getDeliverySource,
    addDeliverySource,
    editDeliverySource,
    deleteDeliverySource,
} from '../../config/config'

import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_DELIVERY_SOURCES_REQUEST = 'GET_DELIVERY_SOURCES_REQUEST'
export const GET_DELIVERY_SOURCES_SUCCESS = 'GET_DELIVERY_SOURCES_SUCCESS'
export const GET_DELIVERY_SOURCES_ERROR = 'GET_DELIVERY_SOURCES_ERROR'

export const ADD_DELIVERY_SOURCES_STARTED = 'ADD_DELIVERY_SOURCES_STARTED'
export const ADD_DELIVERY_SOURCES_END = 'ADD_DELIVERY_SOURCES_END'

export const EDIT_DELIVERY_SOURCES_STARTED = 'EDIT_DELIVERY_SOURCES_STARTED'
export const EDIT_DELIVERY_SOURCES_END = 'EDIT_DELIVERY_SOURCES_END'

export const UPDATE_DELIVERY_SOURCES_STARTED = 'UPDATE_DELIVERY_SOURCES_STARTED'
export const UPDATE_DELIVERY_SOURCES_END = 'UPDATE_DELIVERY_SOURCES_END'

export const DELETE_DELIVERY_SOURCES_STARTED = 'DELETE_DELIVERY_SOURCES_STARTED'
export const DELETE_DELIVERY_SOURCES_END = 'DELETE_DELIVERY_SOURCES_END'

export const SET_DELIVERY_SOURCES_POPUP = 'SET_DELIVERY_SOURCES_POPUP'
export const CLEAR_ALL_DELIVERY_SOURCES = 'CLEAR_ALL_DELIVERY_SOURCES'

export const BULK_UPDATE_DELIVERY_SOURCES_STARTED = 'BULK_UPDATE_DELIVERY_SOURCES_STARTED'
export const BULK_UPDATE_DELIVERY_SOURCES_ENDED = 'BULK_UPDATE_DELIVERY_SOURCES_ENDED'

const actions={

getDeliverySources: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().deliverySources.selectedRestaurant
    if (ruuid !== currentRestaurant)
        dispatch({
        type: GET_DELIVERY_SOURCES_REQUEST,
        payload: ruuid
        })
    await getDeliverySources(ruuid).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_DELIVERY_SOURCES_SUCCESS,
            payload: {
              deliverySources: res?.data?.deliverySources,
            },
          })
        } else {
        handleError(dispatch)(res, GET_DELIVERY_SOURCES_ERROR, 'GET DELIVERY SOURCES ERROR')
        }
    })
    },

  addDeliverySource: (ruuid, data, handleClose) => async (dispatch) => {
    dispatch({
      type: ADD_DELIVERY_SOURCES_STARTED,
    })
    await addDeliverySource(ruuid, data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_DELIVERY_SOURCES_SUCCESS,
          payload: {
            deliverySources: res?.data?.deliverySources,
          },
        })
        handleClose();
      } else {
        handleError(dispatch)(res, null, 'ADD DELIVERY SOURCES ERROR')
      }
    })
    dispatch({
      type: ADD_DELIVERY_SOURCES_END,
    })
  },

   editDeliverySource: (ruuid, uuid, data, handleClose) => async (dispatch) => {
      dispatch({
        type: EDIT_DELIVERY_SOURCES_STARTED,
      })
      await editDeliverySource(ruuid, uuid, data).then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message || 'Successfully Saved')
          dispatch({
            type: GET_DELIVERY_SOURCES_SUCCESS,
            payload: {
              deliverySources: res?.data?.deliverySources,
            },
          })
          handleClose();
        } else {
          handleError(dispatch)(res, null, 'EDIT DELIVERY SOURCES ERROR')
        }
      })
      dispatch({
        type: EDIT_DELIVERY_SOURCES_END,
      })
    },
  
      deleteDeliverySource: (ruuid, uuid) => async (dispatch) => {
        dispatch({
          type: DELETE_DELIVERY_SOURCES_STARTED,
        })
        await deleteDeliverySource(ruuid, uuid).then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message || 'Successfully Deleted')
            dispatch({
              type: GET_DELIVERY_SOURCES_SUCCESS,
              payload: {
                deliverySources: res?.data?.deliverySources,
              },
            })
          } else {
            handleError(dispatch)(res, null, 'DELETE DELIVERY SOURCES ERROR')
          }
        })
        dispatch({
          type: DELETE_DELIVERY_SOURCES_END,
        })
      },
};


export default actions;