import {
    addQR,
    addQrTables,
    addQrButton,
    editQrButton,
    deleteQrButton,
    deleteQrTable,
    getQrButton,
    getQrButtons,
    getQr,
    validateURL,
    getQrTables,
    getTableOrderingSettings,
    addEditTableOrderingSettings,
    getQrOnlineDelivery,
    addOnlineDeliveryQr,
    //entity qr functions
    getEntityQr,
    addEditEntityQr,
    getEntityQrTables,
    addEntityQrTables,
    deleteEntityQrTable,
    getEntityQrButton,
    getEntityQrButtons,
    addEntityQrButton,
    editEntityQrButton,
    deleteEntityQrButton
} from '../../config/config'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const ADD_QR_STARTED = 'ADD_QR_STARTED'
export const ADD_QR_ENDED = 'ADD_QR_ENDED'

export const ADD_EDIT_TABLE_ORDERING_SETTINGS_STARTED = 'ADD_EDIT_TABLE_ORDERING_SETTINGS_STARTED'
export const ADD_EDIT_TABLE_ORDERING_SETTINGS_ENDED = 'ADD_EDIT_TABLE_ORDERING_SETTINGS_ENDED'

export const GET_TABLE_ORDERING_SETTINGS_REQUEST = 'GET_TABLE_ORDERING_SETTINGS_REQUEST'
export const GET_TABLE_ORDERING_SETTINGS_SUCCESS = 'GET_TABLE_ORDERING_SETTINGS_SUCCESS'
export const GET_TABLE_ORDERING_SETTINGS_ERROR = 'GET_TABLE_ORDERING_SETTINGS_ERROR'

export const GET_ONLINE_DELIVERY_QR_REQUEST = 'GET_ONLINE_DELIVERY_QR_REQUEST'
export const GET_ONLINE_DELIVERY_QR_SUCCESS = 'GET_ONLINE_DELIVERY_QR_SUCCESS'
export const GET_ONLINE_DELIVERY_QR_ERROR = 'GET_ONLINE_DELIVERY_QR_ERROR'

export const ADD_ONLINE_DELIVERY_QR_STARTED = 'ADD_ONLINE_DELIVERY_QR_STARTED'
export const ADD_ONLINE_DELIVERY_QR_ENDED = 'ADD_ONLINE_DELIVERY_QR_ENDED'

export const ADD_QR_TABLES_STARTED = 'ADD_QR_TABLES_STARTED'
export const ADD_QR_TABLES_ENDED = 'ADD_QR_TABLES_ENDED'

export const ADD_QR_BUTTON_STARTED = 'ADD_QR_BUTTON_STARTED'
export const ADD_QR_BUTTON_ENDED = 'ADD_QR_BUTTON_ENDED'

export const EDIT_QR_BUTTON_STARTED = 'EDIT_QR_BUTTON_STARTED'
export const EDIT_QR_BUTTON_ENDED = 'EDIT_QR_BUTTON_ENDED'

export const DELETE_QR_BUTTON_STARTED = 'DELETE_QR_BUTTON_STARTED'
export const DELETE_QR_BUTTON_ENDED = 'DELETE_QR_BUTTON_ENDED'

export const DELETE_QR_TABLE_STARTED = 'DELETE_QR_TABLE_STARTED'
export const DELETE_QR_TABLE_ENDED = 'DELETE_QR_TABLE_ENDED'

export const GET_QR_BUTTON_REQUEST = 'GET_QR_BUTTON_REQUEST'
export const GET_QR_BUTTON_SUCCESS = 'GET_QR_BUTTON_SUCCESS'
export const GET_QR_BUTTON_ERROR = 'GET_QR_BUTTON_ERROR'

export const GET_QR_BUTTONS_REQUEST = 'GET_QR_BUTTONS_REQUEST'
export const GET_QR_BUTTONS_SUCCESS = 'GET_QR_BUTTONS_SUCCESS'
export const GET_QR_BUTTONS_ERROR = 'GET_QR_BUTTONS_ERROR'

export const GET_QR_REQUEST = 'GET_QR_REQUEST'
export const GET_QR_SUCCESS = 'GET_QR_SUCCESS'
export const GET_QR_ERROR = 'GET_QR_ERROR'

export const GET_QR_TABLES_REQUEST = 'GET_QR_TABLES_REQUEST'
export const GET_QR_TABLES_SUCCESS = 'GET_QR_TABLES_SUCCESS'
export const GET_QR_TABLES_ERROR = 'GET_QR_TABLES_ERROR'

export const VALIDATE_URL_STARTED = 'VALIDATE_URL_STARTED'
export const VALIDATE_URL_ENDED = 'VALIDATE_URL_ENDED'
export const VALIDATE_URL_SUCCESS = 'VALIDATE_URL_SUCCESS'

export const SET_QR_SETTINGS_MODAL_STATE = 'SET_SETTINGS_MODAL_STATE'

export const CLEAR_ALL_QR = 'CLEAR_ALL_QR'

export const CLEAR_QR_LOADING = 'CLEAR_QR_LOADING'

//entity qr
export const GET_ENTITY_QR_REQUEST = 'GET_ENTITY_QR_REQUEST'
export const GET_ENTITY_QR_SUCCESS = 'GET_ENTITY_QR_SUCCESS'
export const GET_ENTITY_QR_ERROR = 'GET_ENTITY_QR_ERROR'

export const ADD_EDIT_ENTITY_QR_STARTED = 'ADD_EDIT_ENTITY_QR_STARTED'
export const ADD_EDIT_ENTITY_QR_ENDED = 'ADD_EDIT_ENTITY_QR_ENDED'

//entity qr tables
export const GET_ENTITY_QR_TABLES_REQUEST = 'GET_ENTITY_QR_TABLES_REQUEST'
export const GET_ENTITY_QR_TABLES_SUCCESS = 'GET_ENTITY_QR_TABLES_SUCCESS'
export const GET_ENTITY_QR_TABLES_ERROR = 'GET_ENTITY_QR_TABLES_ERROR'

export const ADD_ENTITY_QR_TABLES_STARTED = 'ADD_ENTITY_QR_TABLES_STARTED'
export const ADD_ENTITY_QR_TABLES_ENDED = 'ADD_ENTITY_QR_TABLES_ENDED'

export const DELETE_ENTITY_QR_TABLE_STARTED = 'DELETE_ENTITY_QR_TABLE_STARTED'
export const DELETE_ENTITY_QR_TABLE_ENDED = 'DELETE_ENTITY_QR_TABLE_ENDED'

//entity qr buttons
export const GET_ENTITY_QR_BUTTON_REQUEST = 'GET_ENTITY_QR_BUTTON_REQUEST'
export const GET_ENTITY_QR_BUTTON_SUCCESS = 'GET_ENTITY_QR_BUTTON_SUCCESS'
export const GET_ENTITY_QR_BUTTON_ERROR = 'GET_ENTITY_QR_BUTTON_ERROR'

export const GET_ENTITY_QR_BUTTONS_REQUEST = 'GET_ENTITY_QR_BUTTONS_REQUEST'
export const GET_ENTITY_QR_BUTTONS_SUCCESS = 'GET_ENTITY_QR_BUTTONS_SUCCESS'
export const GET_ENTITY_QR_BUTTONS_ERROR = 'GET_ENTITY_QR_BUTTONS_ERROR'

export const ADD_ENTITY_QR_BUTTON_STARTED = 'ADD_ENTITY_QR_BUTTON_STARTED'
export const ADD_ENTITY_QR_BUTTON_ENDED = 'ADD_ENTITY_QR_BUTTON_ENDED'

export const EDIT_ENTITY_QR_BUTTON_STARTED = 'EDIT_ENTITY_QR_BUTTON_STARTED'
export const EDIT_ENTITY_QR_BUTTON_ENDED = 'EDIT_ENTITY_QR_BUTTON_ENDED'

export const DELETE_ENTITY_QR_BUTTON_STARTED = 'DELETE_ENTITY_QR_BUTTON_STARTED'
export const DELETE_ENTITY_QR_BUTTON_ENDED = 'DELETE_ENTITY_QR_BUTTON_ENDED'

const actions = {
    getTableOrderingSettings: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentBranch = getState().qr.selectedBranchSettings
        if (bruuid !== currentBranch)
            dispatch({
                type: GET_TABLE_ORDERING_SETTINGS_REQUEST,
                payload: bruuid
            })
        await getTableOrderingSettings(ruuid, bruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_TABLE_ORDERING_SETTINGS_SUCCESS,
                    payload: response.data.tableOrderingSettings
                })
            else {
                handleError(dispatch)(response, GET_TABLE_ORDERING_SETTINGS_ERROR, 'GET TABLE ORDERING SETTINGS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD QR TABLES ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
    },
    addEditTableOrderingSettings: (ruuid, bruuid, data, close) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_TABLE_ORDERING_SETTINGS_STARTED
        })
        await addEditTableOrderingSettings(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.statusText || 'ADD EDIT TABLE ORDERING SETTINGS SUCCESS')
                dispatch({
                    type: GET_TABLE_ORDERING_SETTINGS_SUCCESS,
                    payload: response.data.tableOrderingSettings
                })
                if (close)
                    close()
            } else {
                handleError(dispatch)(response, null, 'ADD EDIT TABLE ORDERING ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.statusText || 'ADD EDIT TABLE ORDERING SETTINGS ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_EDIT_TABLE_ORDERING_SETTINGS_ENDED
        })
    },
    setSettingsModalState: (data) => (dispatch) => {
        dispatch({
            type: SET_QR_SETTINGS_MODAL_STATE,
            payload: data
        })
    },
    addQR: (ruuid, bruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_QR_STARTED
        })
        let addedQR = null;
        await addQR(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD QR SUCCESS')
                addedQR = response.data.qr;
                dispatch({
                    type: GET_QR_SUCCESS,
                    payload: addedQR,
                })
            } else {
                handleError(dispatch)(response, null, 'ADD QR ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD QR ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })

        const getQRResponse = await getQr(ruuid, bruuid);
        if (getQRResponse.status === 200 && addedQR && addedQR === getQRResponse.data.qr) {
            dispatch({
                type: GET_QR_SUCCESS,
                payload: getQRResponse.data.qr
            });
        }
        dispatch({
            type: ADD_QR_ENDED
        })
    },
    addQrTables: (ruuid, bruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_QR_TABLES_STARTED
        })
        await addQrTables(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD QR TABLES SUCCESS')
                dispatch({
                    type: GET_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
            } else {
                handleError(dispatch)(response, null, 'ADD QR TABLES ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD QR TABLES ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_QR_TABLES_ENDED
        })
    },
    addQrButton: (ruuid, bruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_QR_BUTTON_STARTED
        })
        await addQrButton(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD QR BUTTON SUCCESS')
                dispatch({
                    type: GET_QR_BUTTONS_SUCCESS,
                    payload: response.data.buttons
                })
            } else {
                handleError(dispatch)(response, null, 'ADD QR BUTTON ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD QR BUTTON ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_QR_BUTTON_ENDED
        })
    },
    editQrButton: (ruuid, bruuid, uuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_QR_BUTTON_STARTED
        })
        await editQrButton(ruuid, bruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'EDIT QR BUTTON SUCCESS')
                dispatch({
                    type: GET_QR_BUTTONS_SUCCESS,
                    payload: response.data.buttons
                })
            } else {
                handleError(dispatch)(response, null, 'EDIT QR BUTTON ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'EDIT QR BUTTON ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_QR_BUTTON_ENDED
        })
    },
    deleteQrButton: (ruuid, bruuid, set, deleted) => async (dispatch) => {
        dispatch({
            type: DELETE_QR_BUTTON_STARTED
        })
        let final = [...deleted]
        await Promise.all(deleted.map(async (deletedButton) => {
            if (!deletedButton.isAdded) {
                await deleteQrButton(ruuid, bruuid, deletedButton.uuid).then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data.message || 'DELETE BUTTON SUCCESS')
                        final = final.filter((del) => del.uuid !== deletedButton.uuid)
                        dispatch({
                            type: GET_QR_BUTTONS_SUCCESS,
                            payload: response.data.buttons
                        })
                    } else {
                        handleError(dispatch)(response, null, 'DELETE BUTTON ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response.data.message || 'DELETE BUTTON ERROR')
                        // if (response.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response.data.permissions
                        //     })
                    }
                })
            }
        }));
        set(final)
        // await deleteQrButton(ruuid, bruuid, uuid).then((response) => {
        //     if (response.status === 200) {
        //         toast.success(response.data.message || 'DELETE BUTTON SUCCESS')
        //         let final = [...deleted]
        //         final = final.filter((del) => del.uuid !== uuid)
        //         set(final)
        //         dispatch({
        //             type: GET_QR_BUTTONS_SUCCESS,
        //             payload: response.data.buttons
        //         })
        //     } else {
        // if (response.status !== 401 && response.status !== 403)//         
        //    toast.error(response.data.message || 'DELETE BUTTON ERROR')
        //         if (response.status === 401)
        //             dispatch({
        //                 type: LOGOUT_USER
        //             })
        //         if (response.status === 403)
        //             dispatch({
        //                 type: SET_PERMISSIONS,
        //                 payload: response.data.permissions
        //             })
        //     }
        // })
        dispatch({
            type: DELETE_QR_BUTTON_ENDED
        })
    },
    deleteQrTable: (ruuid, bruuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_QR_TABLE_STARTED
        })
        await deleteQrTable(ruuid, bruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'DELETE TABLE SUCCESS')
                dispatch({
                    type: GET_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
                close()
            } else {
                handleError(dispatch)(response, null, 'DELETE TABLE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'DELETE TABLE ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_QR_TABLE_ENDED
        })
    },
    getQrButton: (ruuid, bruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_QR_BUTTON_REQUEST
        })
        await getQrButton(ruuid, bruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_QR_BUTTON_SUCCESS,
                    payload: response.data.button
                })
            else {
                handleError(dispatch)(response, GET_QR_BUTTON_ERROR, 'GET QR BUTTON ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET QR BUTTON ERROR')
                // dispatch({
                //     type: GET_QR_BUTTON_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
    },
    getQrButtons: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().qr.selectedRestaurantButtons
        const currentBranch = getState().qr.selectedBranchButtons
        if (ruuid !== currentRestaurant || bruuid !== currentBranch)
            dispatch({
                type: GET_QR_BUTTONS_REQUEST,
                payload: {
                    ruuid: ruuid,
                    bruuid: bruuid
                }
            })
        await getQrButtons(ruuid, bruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_QR_BUTTONS_SUCCESS,
                    payload: response.data.buttons
                })
            else {
                handleError(dispatch)(response, GET_QR_BUTTONS_ERROR, 'GET QR BUTTONS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET QR BUTTON ERROR')
                // dispatch({
                //     type: GET_QR_BUTTONS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
    },
    getQr: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().qr.selectedRestaurantQR
        const currentBranch = getState().qr.selectedBranchQR
        if (ruuid !== currentRestaurant || bruuid !== currentBranch)
            dispatch({
                type: GET_QR_REQUEST,
                payload: {
                    ruuid: ruuid,
                    bruuid: bruuid
                }
            })
        await getQr(ruuid, bruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_QR_SUCCESS,
                    payload: response.data.qr
                })
            } else {
                handleError(dispatch)(response, GET_QR_ERROR, 'GET QR ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET QR ERROR')
                // dispatch({
                //     type: GET_QR_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403)
                //     dispatch({
                //         type: SET_PERMISSIONS
                //     })
            }
        })
    },
    getQrOnlineDelivery: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().qr.selectedRestaurantQROnlineDelivery

        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_ONLINE_DELIVERY_QR_REQUEST,
                payload: {
                    ruuid: ruuid,
                }
            })
        await getQrOnlineDelivery(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_ONLINE_DELIVERY_QR_SUCCESS,
                    payload: response.data.qr
                })
            } else {
                handleError(dispatch)(response, GET_ONLINE_DELIVERY_QR_ERROR, 'GET QR ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET QR ERROR')
                // dispatch({
                //     type: GET_QR_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403)
                //     dispatch({
                //         type: SET_PERMISSIONS
                //     })
            }
        })
    },
    addQROnlineDelivery: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_ONLINE_DELIVERY_QR_STARTED
        })
        let addedQR = null;
        await addOnlineDeliveryQr(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD QR SUCCESS')
                addedQR = response.data.qr;
                dispatch({
                    type: GET_ONLINE_DELIVERY_QR_SUCCESS,
                    payload: addedQR,
                })
            } else {
                handleError(dispatch)(response, null, 'ADD QR ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'ADD QR ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })

        const getQRResponse = await getQrOnlineDelivery(ruuid);
        if (getQRResponse.status === 200 && addedQR && addedQR === getQRResponse.data.qr) {
            dispatch({
                type: GET_ONLINE_DELIVERY_QR_SUCCESS,
                payload: getQRResponse.data.qr
            });
        }
        dispatch({
            type: ADD_ONLINE_DELIVERY_QR_ENDED
        })
    },
    validateURL: (ruuid, bruuid, data) => async (dispatch) => {
        dispatch({
            type: VALIDATE_URL_STARTED
        })
        await validateURL(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'VALIDATE URL SUCCESS')
                dispatch({
                    type: VALIDATE_URL_SUCCESS,
                    payload: response.data
                })
            } else {
                handleError(dispatch)(response, null, 'VALIDATE URL ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'VALIDATE URL ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: VALIDATE_URL_ENDED
        })
    },
    getQrTables: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().qr.selectedRestaurantTables
        const currentBranch = getState().qr.selectedBranchTables
        if (ruuid !== currentRestaurant || bruuid !== currentBranch)
            dispatch({
                type: GET_QR_TABLES_REQUEST,
                payload: {
                    ruuid: ruuid,
                    bruuid: bruuid
                }
            })
        await getQrTables(ruuid, bruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
            } else {
                handleError(dispatch)(response, GET_QR_TABLES_ERROR, 'GET QR TABLES ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET QR TABLES ERROR')
                // dispatch({
                //     type: GET_QR_TABLES_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
                // }
            }
        })
    },
    //entity qr actions
    getEntityQr: (euuid) => async (dispatch, getState) => {
        const currentEntity = getState().qr.selectedEntityQr
        if (euuid !== currentEntity)
            dispatch({
                type: GET_ENTITY_QR_REQUEST,
                payload: {
                    euuid: euuid
                }
            })
        await getEntityQr(euuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_ENTITY_QR_SUCCESS,
                    payload: response.data.qr
                })
            } else {
                handleError(dispatch)(response, GET_ENTITY_QR_ERROR, 'GET ENTITY QR ERROR')
            }
        })
    },
    addEditEntityQr: (euuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EDIT_ENTITY_QR_STARTED
        })
        let addedEntityQR = null;
        await addEditEntityQr(euuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD ENTITY QR SUCCESS')
                addedEntityQR = response.data.qr;
                dispatch({
                    type: GET_ENTITY_QR_SUCCESS,
                    payload: addedEntityQR,
                })
            } else {
                handleError(dispatch)(response, null, 'ADD ENTITY QR ERROR')
            }
        })

        const getEntityQRResponse = await getEntityQr(euuid);
        if (getEntityQRResponse.status === 200 && addedEntityQR && addedEntityQR === getEntityQRResponse.data.qr) {
            dispatch({
                type: GET_ENTITY_QR_SUCCESS,
                payload: getEntityQRResponse.data.qr
            });
        }
        dispatch({
            type: ADD_EDIT_ENTITY_QR_ENDED
        })
    },
    //entity qr tables actions
    getEntityQrTables: (euuid) => async (dispatch, getState) => {
        const currentEntity = getState().qr.selectedEntityTables
        if (euuid !== currentEntity)
            dispatch({
                type: GET_ENTITY_QR_TABLES_REQUEST,
                payload: {
                    euuid: euuid
                }
            })
        await getEntityQrTables(euuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_ENTITY_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
            } else {
                handleError(dispatch)(response, GET_ENTITY_QR_TABLES_ERROR, 'GET ENTITY QR TABLES ERROR')
            }
        })
    },
    addEntityQrTables: (euuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_ENTITY_QR_TABLES_STARTED
        })
        await addEntityQrTables(euuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD ENTITY QR TABLES SUCCESS')
                dispatch({
                    type: GET_ENTITY_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
            } else {
                handleError(dispatch)(response, null, 'ADD ENTITY QR TABLES ERROR')
            }
        })
        dispatch({
            type: ADD_ENTITY_QR_TABLES_ENDED
        })
    },
    deleteEntityQrTable: (euuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_ENTITY_QR_TABLE_STARTED
        })
        await deleteEntityQrTable(euuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'DELETE TABLE SUCCESS')
                dispatch({
                    type: GET_ENTITY_QR_TABLES_SUCCESS,
                    payload: response.data.tables
                })
                if (close)
                close()
            } else {
                handleError(dispatch)(response, null, 'DELETE TABLE ERROR')
            }
        })
        dispatch({
            type: DELETE_ENTITY_QR_TABLE_ENDED
        })
    },
    //entity qr buttons actions
    getEntityQrButton: (euuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_ENTITY_QR_BUTTON_REQUEST
        })
        await getEntityQrButton(euuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_ENTITY_QR_BUTTON_SUCCESS,
                    payload: response.data.button
                })
            else {
                handleError(dispatch)(response, GET_ENTITY_QR_BUTTON_ERROR, 'GET ENTITY QR BUTTON ERROR')
            }
        })
    },
    getEntityQrButtons: (euuid) => async (dispatch, getState) => {
        const currentEntity = getState().qr.selectedEntityButtons
        if (euuid !== currentEntity)
            dispatch({
                type: GET_ENTITY_QR_BUTTONS_REQUEST,
                payload: {
                    euuid: euuid
                }
            })
        await getEntityQrButtons(euuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_ENTITY_QR_BUTTONS_SUCCESS,
                    payload: response?.data?.buttons
                })
            else {
                handleError(dispatch)(response, GET_ENTITY_QR_BUTTONS_ERROR, 'GET ENTITY QR BUTTONS ERROR')
            }
        })
    },
    addEntityQrButton: (euuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_ENTITY_QR_BUTTON_STARTED
        })
        await addEntityQrButton(euuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD ENTITY QR BUTTON SUCCESS')
                dispatch({
                    type: GET_ENTITY_QR_BUTTONS_SUCCESS,
                    payload: response?.data?.buttons
                })
            } else {
                handleError(dispatch)(response, null, 'ADD ENTITY QR BUTTON ERROR')
            }
        })
        dispatch({
            type: ADD_ENTITY_QR_BUTTON_ENDED
        })
    },
    editEntityQrButton: (euuid, uuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_ENTITY_QR_BUTTON_STARTED
        })
        await editEntityQrButton(euuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'EDIT ENTITY QR BUTTON SUCCESS')
                dispatch({
                    type: GET_ENTITY_QR_BUTTONS_SUCCESS,
                    payload: response.data.buttons
                })
            } else {
                handleError(dispatch)(response, null, 'EDIT ENTITY QR BUTTON ERROR')
            }
        })
        dispatch({
            type: EDIT_ENTITY_QR_BUTTON_ENDED
        })
    },
    deleteEntityQrButton: (euuid, set, deleted) => async (dispatch) => {
        dispatch({
            type: DELETE_ENTITY_QR_BUTTON_STARTED
        })
        let final = [...deleted]
        await Promise.all(deleted.map(async (deletedButton) => {
            if (!deletedButton.isAdded) {
                await deleteEntityQrButton(euuid, deletedButton.uuid).then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data.message || 'DELETE ENTITY BUTTON SUCCESS')
                        final = final.filter((del) => del.uuid !== deletedButton.uuid)
                        dispatch({
                            type: GET_ENTITY_QR_BUTTONS_SUCCESS,
                            payload: response.data.buttons
                        })
                    } else {
                        handleError(dispatch)(response, null, 'DELETE ENTITY BUTTON ERROR')
                    }
                })
            }
        }));
        set(final)
        dispatch({
            type: DELETE_ENTITY_QR_BUTTON_ENDED
        })
    },
}
export default actions
